import { useEffect, useState } from "react"
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Typography,
} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { supabase } from "../../services/supabase";
import { deleteSBEvent, deletePlanClient } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { useCalendar } from "../../providers/CalendarProvider";
import { buttonStyle, MenuIcon } from "./style"
import { AddNewTraining } from "./AddNewTrainnig";
import { ViewExerciseAndPlan } from "./ViewExerciseAndPlan";



export const ClientDetailTraining = ({ events, height, date, onNewEventClick, onEventDelete = () => null, currentAthlete, reload }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open
    const { deleteEvent } = useCalendar();
    const [SelectedPlan, setSelectedPlan] = useState([])
    const [newTraining, setnewTraining] = useState(false);
    const [Title, setTitle] = useState("");


    const deleteCEvent = async (calId, evId, gc_id, plcid) => {
        await deleteEvent(calId, gc_id);
        await deleteSBEvent(supabase, evId);
        await deletePlanClient(supabase, plcid)
        onEventDelete(calId, evId);
        reload();
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index); // Set the index of the clicked plan
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null); // Close the menu
    };
    useEffect(() => {
        setnewTraining(false)
        setOpenMenuIndex(null)
        setSelectedPlan([])
    }, [events])

    return (
        <Paper variant="outlined" sx={{ borderRadius: "24px", height: height, backgroundColor: "#FBFBFB ", border: "none" }}>
            <Box sx={{ height: "45px", backgroundColor: "#F0F9FF", borderTopLeftRadius: "24px", borderTopRightRadius: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color={"#043A8D"} fontSize={"12px"}>{SelectedPlan.length === 0 && !newTraining ? date : Title}</Typography>
            </Box>
            <Divider mb={2} />
            {!newTraining && SelectedPlan.length === 0 &&
                (<>
                    <Box pt={4} pb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography color={"#525A65"} fontSize={"16px"} fontWeight={"600 !important"}>Training Program</Typography>
                    </Box>
                    <Box sx={{
                        maxHeight: "450px",
                        display: "flex",
                        height: events.length > 0 ? Array.isArray(events.events) && !events.events[0] ? "450px" : "auto" : "450px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        pr: 1,
                        pl: 1
                    }}>

                        {events.length === 0 ? (
                            <>
                                <Typography color={"#525A65"} fontSize={"20px"} >No scheduled training for today</Typography>
                            </>
                        ) : <>
                            <TableContainer sx={{ height: "100%" }}>
                                <Table stickyHeader aria-label="sticky table" sx={{
                                    borderSpacing: "10px 11px",
                                    borderCollapse: "unset",
                                    border: "none !important",
                                }}>
                                    <TableBody>
                                        {events.map((plan, i) => (
                                            <TableRow key={i} role="checkbox" tabIndex={-1}>
                                                <TableCell sx={{
                                                    borderRadius: "8px", backgroundColor: "#F1F3F4",
                                                    padding: "12px",
                                                    borderBottom: "none !important", lineHeight: "0px !important"
                                                }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm container display={"flex"} alignItems={"center"}>
                                                            <Typography sx={{ ml: 1, color: "#525A65", fontSize: { md: "14px", xs: "10px" }, fontWeight: "400 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>
                                                                {plan.plan[0]?.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item pt={"12px"}>
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", paddingRight: "8px" }}>
                                                                <IconButton
                                                                    id="basic-button"
                                                                    aria-controls={openMenuIndex === i ? 'basic-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    sx={{ padding: "16px 10px 16px 10px !important" }}
                                                                    aria-expanded={openMenuIndex === i ? 'true' : undefined}
                                                                    onClick={(event) => handleClick(event, i)} // Pass the index
                                                                >
                                                                    <MenuIcon />
                                                                </IconButton>
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={openMenuIndex === i}
                                                                    onClose={handleClose}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'basic-button',
                                                                    }}
                                                                >
                                                                    <MenuItem onClick={() => {
                                                                        setSelectedPlan([plan])
                                                                        handleClose();
                                                                    }}>View</MenuItem>
                                                                    <MenuItem onClick={() => {
                                                                        console.log(plan)
                                                                        if (window.confirm("Are you sure you want to delete this event?")) {
                                                                            deleteCEvent(plan.calendarId, plan.eventId, plan.resource.id, plan.plan[0].id);
                                                                            onEventDelete(plan.calendarId, plan.eventId, plan.resource.id);
                                                                        }
                                                                        handleClose();

                                                                    }}>Delete</MenuItem>
                                                                </Menu>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}
                        <Button sx={buttonStyle} onClick={() => {
                            if (!date) return alert("Select a Date")
                            setnewTraining(true)
                        }}>+ Assign a new training</Button>
                    </Box>
                </>)}
            {newTraining && <AddNewTraining back={() => setnewTraining(false)} onEventDelete={onEventDelete} athlete={currentAthlete} title={setTitle} date={date} reload={reload} />}
            {SelectedPlan.length > 0 && <ViewExerciseAndPlan athelete={SelectedPlan} back={() => setSelectedPlan([])} title={setTitle} reload={reload} />}
        </Paper >
    )
}










