
export const addnewquestionstyle = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  fontSize: "12px",
  ".MuiSvgIcon-root": {
    fontSize: "12px !important",
    paddingLeft: "2px",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}

export const invitestyle = {
  marginLeft: "auto",
  color: "#A080E8",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "30px",
  marginLeft: "auto",
  fontSize: "13px",
  padding: "11px 8px !important",
  minWidth: "96px !important",
  "&:hover": {
    color: "#A080E8",
    backgroundColor: "unset"
  },
}

export const close = {
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  lineHeight: "1",
  backgroundColor: "#EEEEEE",
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd"
  },
  ".MuiButton-icon": {
    margin: "0px !important"
  }
}