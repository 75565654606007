import { useEffect, useState, useCallback } from "react"
import {
    Box,
    Button,
    IconButton,
    Paper,
    Typography,
    TextField,
} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { supabase } from "../../services/supabase";
import { getPlansByCoachId, getExercisesByCoachId, insertEvent, getPlanByIdPublic, deletePlanEvents, plan_client_upsert, insertExercisePlans } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { TrainingPlanModal } from "../TrainingPlanModal/TrainingPlanModal"
import { button3Style, searchStyle, LeftArrow, buttonStyle } from "./style"
import { getAthleteName } from "../../utils/selectors";
import {
    getTimeZone,
    renderGCalDescription,
    getSQLDate,
} from "../../utils/calendar";
import { addDays, format } from "date-fns";
import { useCalendar } from "../../providers/CalendarProvider";


export const AddNewTraining = ({ back, athlete, title, date, reload, onEventDelete }) => {
    const { user } = useAuth()
    const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open
    const [SelectedPlan, setSelectedPlan] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [tpSearch, setTpSearch] = useState("");
    const [plans, setPlans] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index); // Set the index of the clicked plan
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null); // Close the menu
    };
    const loadPlans = () => {
        getPlansByCoachId(supabase, user.id).then(({ data }) => {
            setPlans(data || []);
        });
    };
    const loadExercises = () => {
        getExercisesByCoachId(supabase, user.id).then(({ data }) => {
            setExercises(data || []);
        });
    };

    useEffect(() => {
        title("Select Training Plan")
        if (!user) {
            return;
        }
        loadPlans();
        loadExercises();
    }, [user]);

    const { createEvent } = useCalendar();

    const handleSubmit = async (plan) => {
        const currentDate = new Date(date);
        const gcal_id = athlete.calendars[0].gcal_id;

        // Prepare the start and end dates
        const start = format(currentDate, "yyyy-MM-dd");
        const end = format(addDays(currentDate, 1), "yyyy-MM-dd");
        const sb_start = getSQLDate(currentDate);
        const planID = plan?.plan_id ? plan.plan_id : plan.id;

        // Fetch the plan details
        const { data: getplan } = await getPlanByIdPublic(supabase, planID);

        const jsonPlan_Client = { name: plan.name ? plan.name : getplan[0].name, overview: plan.overview ? plan.overview : getplan[0].overview, created_at: new Date(), coach_id: user.id, }
        const { data: plan_client } = await plan_client_upsert(supabase, jsonPlan_Client)


        // Create the Google Calendar event
        const gcal_event = {
            summary: `C+ ${getAthleteName(athlete)} - ${plan.name ? plan.name : getplan[0].name}`,
            description: renderGCalDescription(
                plan_client[0],
                athlete.email,
                plan_client[0].id
            ),
            start: {
                date: start,
                timeZone: getTimeZone(),
            },
            end: {
                date: end,
                timeZone: getTimeZone(),
            },
            reminders: {
                useDefault: true,
            },
        };
        // Create the Supabase event
        const sb_event = {
            date: sb_start,
            calendar_id: athlete.calendars[0].id,
            gcal_id,
            payload: gcal_event,
            coach_id: user.id,
            athlete_id: athlete.id,
            plan_client_id: plan_client[0].id,
            plan_id: planID,
            workout: getplan[0].exercise_plans[0].exercises.workout,
        };

        try {
            const createdGcalEvent = await createEvent(gcal_id, gcal_event);

            await insertEvent(supabase, { ...sb_event, payload: createdGcalEvent });

            const exercisePlans = getplan[0].exercise_plans.map(e => ({
                exercise_id: e.exercise_id,
                plan_id: planID,
                plan_client_id: plan_client[0].id,
                order: e.order,
                notes: e.notes,
                sets: e.sets,
                isSuperset: e.isSuperset,
                supersetGroup: e.supersetGroup
            }));
            await insertExercisePlans(supabase, exercisePlans)

        } catch (err) {
            alert("Error creating events: " + err);
        }
        reload();
        back();
    };

    const throttledHandleSubmit = useCallback(async (plan) => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        try {
            await handleSubmit(plan);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 1000);
        }
    }, [isSubmitting]);

    return (
        <>
            <Paper sx={{ backgroundColor: "#FBFBFB", borderRadius: "24px", height: "555px", display: trainingPlanOpen ? "none" : "block", border: "none", boxShadow: "unset" }}>
                <Button startIcon={<LeftArrow />} onClick={back} sx={{
                    fontSize: "12px", textTransform: "none", ml: 2, mt: 2, mb: 1, ".MuiButton-icon": {
                        marginRight: "3px",
                    }
                }}>
                    <span style={{ marginTop: "1px" }}>
                        Back
                    </span>
                </Button>
                <Box p={3} pb={0} pt={1} sx={{ height: "100px" }}>
                    <Box pb={2} display={"flex"} alignItems={"flex-end"} >
                        <Typography sx={{ fontSize: { xl: "16px", lg: "16px", md: "13px", sm: "13px", xs: "13px" }, fontWeight: "600 !important", color: "#525A65" }}>My training plans</Typography>
                        <Button ml={"auto !important"} sx={button3Style} onClick={() => setTrainingPlanOpen(true)}
                        >+ Add new training plan
                        </Button>
                    </Box>

                    <TextField
                        id="outlined-search"
                        label="Search plan by name..."
                        type="search"
                        fullWidth
                        value={tpSearch}
                        sx={searchStyle}
                        onChange={(e) => setTpSearch(e.target.value)}
                    />
                </Box>
                <Box pl={2} pr={2} >
                    <TableContainer sx={{ height: 390 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{
                            borderSpacing: "10px 11px",
                            borderCollapse: "unset",
                            border: "none !important",
                        }}>
                            <TableBody>
                                {plans.filter((plan) => plan.name.toLowerCase().includes(tpSearch.toLowerCase()))
                                    .map((plan, i) => (
                                        <TableRow key={i} role="checkbox" tabIndex={-1}>
                                            <TableCell
                                                onClick={(e) => {
                                                    if (e.target.closest('#basic-button') || e.target.closest('#basic-menu')) {
                                                        return;
                                                    }
                                                    throttledHandleSubmit(plan);
                                                }}
                                                sx={{
                                                    borderRadius: "8px",
                                                    backgroundColor: "#F1F3F4",
                                                    padding: "17px 0px 11px 22px !important",
                                                    borderBottom: "none !important",
                                                    lineHeight: "0px !important",
                                                    cursor: isSubmitting ? 'wait' : 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: "#E8EAEB",
                                                    }
                                                }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm container>
                                                        <Grid item xs container direction="column" spacing={2}>
                                                            <Grid item xs>
                                                                <Typography sx={{ mb: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "600 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.name}</Typography>
                                                                <Typography gutterBottom sx={{ mb: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "400 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.overview?.substring(0, 150)}</Typography>
                                                                <Typography sx={{ mb: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "400 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.exercise_plans.map((t) => t.exercises?.title + ",")}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item pt={"12px"}>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", paddingRight: "8px" }}>
                                                            <IconButton
                                                                id="basic-button"
                                                                aria-controls={openMenuIndex === i ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                sx={{ padding: "16px 10px 16px 10px !important" }}
                                                                aria-expanded={openMenuIndex === i ? 'true' : undefined}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleClick(event, i);
                                                                }}
                                                            >
                                                                <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#525A65" />
                                                                    <circle cx="8" cy="2" r="2" transform="rotate(-90 8 2)" fill="#525A65" />
                                                                    <circle cx="14" cy="2" r="2" transform="rotate(-90 14 2)" fill="#525A65" />
                                                                </svg>
                                                            </IconButton>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={openMenuIndex === i}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <MenuItem onClick={() => {
                                                                    setTrainingPlanOpen(true);
                                                                    setSelectedPlan(plan);
                                                                    handleClose()
                                                                }}>View/Edit</MenuItem>
                                                                <MenuItem onClick={() => handleSubmit(plan)}>Select</MenuItem>
                                                                <MenuItem onClick={async () => {
                                                                    if (
                                                                        window.confirm(
                                                                            `Deleting training plan: "${plan.name}". Please confirm.`
                                                                        )
                                                                    ) {
                                                                        await deletePlanEvents(supabase, plan.id)
                                                                        loadPlans();
                                                                        reload();
                                                                    }
                                                                    handleClose()
                                                                }}>Delete</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
            {trainingPlanOpen && (
                <TrainingPlanModal
                    exercises={exercises}
                    open={trainingPlanOpen}
                    plan={SelectedPlan}
                    isvisible={true}
                    handleClose={() => {
                        setTrainingPlanOpen(false);
                        setSelectedPlan(null);

                    }}
                    onSuccess={() => {
                        loadPlans();
                        reload();
                    }}
                    onCreateEvent={(planId) => {
                        handleSubmit(planId)
                    }}

                />
            )}
        </>

    )
}