import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Autocomplete,
  IconButton,
  InputLabel
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import {
  deleteExercisePlans,
  insertExercisePlans,
  upsertPlan,
  get_fill_exercise,
  upsertExercise,
  getPlanByIdClient,
  plan_client_upsert,
  deleteExercisePlanClient
} from "../../services/query";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LeftArrow } from "../ClientDetailTraining/style";
import {
  formtextfieldStyle,
  formtextfiel2dStyle,
  formtextfiel3dStyle,
  formtextselectStyle,
  formDescriptionStyle,
  close,
  button2Style,
  CreateUpdateStyle,
  DeleteIcon,
  DragDropIcon,
  button1Style,
  SuperSetIcon
} from "./style";
import PropTypes from 'prop-types';

export const TrainingPlanModal = ({
  open,
  handleClose,
  exercises = [], // Add default value
  plan,
  isvisible,
  onSuccess,
  onCreateEvent,

}) => {
  const [loading, setLoading] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [fillexercise, setFillexercise] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const isUpdate = Boolean(plan?.id);
  const onDragStart = (result) => {
    setDragging(true);
    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);
    setDraggingItem(movedExercise.boxid);
  };
  const handleDelete = useCallback((index) => {
    setSelectedExercises(prev => {
      const updatedExercises = prev.filter((_, i) => i !== index);

      // Check for orphaned superset exercises
      const supersetGroups = new Map();
      updatedExercises.forEach(exercise => {
        if (exercise.supersetGroup) {
          if (!supersetGroups.has(exercise.supersetGroup)) {
            supersetGroups.set(exercise.supersetGroup, []);
          }
          supersetGroups.get(exercise.supersetGroup).push(exercise);
        }
      });

      supersetGroups.forEach((exercises, groupId) => {
        if (exercises.length === 1) {
          exercises[0].isSuperset = false;
          exercises[0].supersetGroup = null;
        }
      });

      return updatedExercises;
    });
  }, [selectedExercises]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      setDragging(false);
      setDraggingItem(null);
      return;
    }

    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);

    const destinationIndex = result.destination.index;
    const destinationExercise = reorderedExercises[destinationIndex];

    if (destinationExercise?.isSuperset) {
      movedExercise.isSuperset = true;
      movedExercise.supersetGroup = destinationExercise.supersetGroup;
    } else {
      movedExercise.isSuperset = false;
      movedExercise.supersetGroup = null;
    }

    reorderedExercises.splice(destinationIndex, 0, movedExercise);

    const updatedExercises = reorderedExercises.map((exercise, index) => ({
      ...exercise,
      boxid: index + 1,
      exercise: {
        ...exercise.exercise,
        order: index + 1
      }
    }));

    setSelectedExercises(updatedExercises);
    setDragging(false);
    setDraggingItem(null);
    handleDelete()
  };


  const handleAddExercise = useCallback(() => {
    const newId = selectedExercises.length + 1;
    setSelectedExercises(prev => [...prev, {
      boxid: newId,
      exercise: [],
      notes: "",
      sets: 1,
      supersetGroup: null,
      isSuperset: false
    }]);
  }, [selectedExercises.length]);

  function generateUUID() {
    const timestamp = Date.now();
    const randomValues = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return `superset-${timestamp}-${randomValues}`;
  }

  const addSuperSet = (index) => {
    if (index < 0 || index >= selectedExercises.length - 1) return;

    setSelectedExercises((prevExercises) => {
      const newExercises = [...prevExercises];
      const current = newExercises[index];
      const next = newExercises[index + 1];

      // Prevent normal exercise from connecting with an existing superset
      if (!current.supersetGroup && next.supersetGroup) {
        return prevExercises;
      }

      const createNewSuperset = () => {
        const supersetId = generateUUID();
        [current, next].forEach((exercise) => {
          exercise.supersetGroup = supersetId;
          exercise.isSuperset = true;
        });
      };

      const extendSuperset = () => {
        next.supersetGroup = current.supersetGroup;
        next.isSuperset = true;
      };

      const mergeSupersets = () => {
        const targetId = current.supersetGroup;
        newExercises.forEach((exercise) => {
          if (exercise.supersetGroup === next.supersetGroup) {
            exercise.supersetGroup = targetId;
            exercise.isSuperset = true;
          }
        });
      };

      if (!current.supersetGroup && !next.supersetGroup) createNewSuperset();
      else if (current.supersetGroup && !next.supersetGroup) extendSuperset();
      else if (current.supersetGroup !== next.supersetGroup) mergeSupersets();

      return newExercises;
    });
  };


  const shouldShowSupersetIcon = useCallback((index) => {
    if (index + 1 >= selectedExercises.length) return false;

    const currentExercise = selectedExercises[index];
    const nextExercise = selectedExercises[index + 1];

    if (dragging && draggingItem === currentExercise.boxid) return false;


    return (
      !currentExercise.supersetGroup ||
      (currentExercise.supersetGroup && !nextExercise.supersetGroup) ||
      (currentExercise.supersetGroup && nextExercise.supersetGroup &&
        currentExercise.supersetGroup !== nextExercise.supersetGroup)
    );
  }, [selectedExercises, dragging, draggingItem]);

  const groupedExercises = useMemo(() => {
    const groupExercisesBySuperset = (exercises) => {
      const groups = [];
      let currentGroup = [];
      let currentSupersetId = null;

      const pushGroup = (type) => {
        if (currentGroup.length > 0) {
          const groupSets = type === 'superset' ? currentGroup[0].exercise.sets : undefined;
          groups.push({ type, exercises: currentGroup, sets: groupSets });
          currentGroup = [];
        }
      };

      exercises.forEach((exercise, index) => {
        const isPartOfSuperset = !!exercise.supersetGroup;

        if (isPartOfSuperset) {
          if (exercise.supersetGroup !== currentSupersetId) {
            pushGroup(currentSupersetId ? 'superset' : 'normal');
            currentSupersetId = exercise.supersetGroup;
          }
          currentGroup.push({ exercise, index });
        } else {
          pushGroup(currentSupersetId ? 'superset' : 'normal');
          currentSupersetId = null;
          currentGroup.push({ exercise, index });
        }
      });

      pushGroup(currentSupersetId ? 'superset' : 'normal');

      return groups;
    };

    return groupExercisesBySuperset(selectedExercises);
  }, [selectedExercises]);

  const { register, handleSubmit } = useForm({
    defaultValues: plan
      ? { name: plan.name, overview: plan.overview }
      : { name: "", overview: "" },
  });

  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    if (!plan) return;

    const selected = plan.exercise_plans.map((e, i) => ({
      boxid: i + 1,
      exercise: { ...e.exercises, order: e.order },
      notes: e.notes,
      sets: e.sets,
      isSuperset: e.isSuperset,
      supersetGroup: e.supersetGroup
    }));

    setSelectedExercises(selected.sort((a, b) => a.exercise.order - b.exercise.order));
  }, [plan]);


  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data: _fillexercise } = await get_fill_exercise(supabase);
        const newItems = _fillexercise.filter(item => item.title !== "Custom");
        const existingWorkouts = exercises.map(i => i.workout);
        const filteredExercises = newItems.filter(
          item => !existingWorkouts.includes(item.title)
        );
        setFillexercise([...exercises, ...filteredExercises]);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
    };

    fetchExercises();
  }, []);

  const handleError = (error) => {
    console.error("Error saving training plan:", error);
    const errorMessage = error.message || "An unexpected error occurred while saving the training plan";
    alert(errorMessage);
  };

  const validateExercises = (exercises) => {
    const errors = [];
    exercises.forEach((exercise, index) => {
      if (!exercise.exercise.title) {
        errors.push(`Exercise at position ${index + 1} is empty`);
      }
    });
    return errors;
  };

  const onSubmit = handleSubmit(async (data) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join('\n'));
      return;
    }

    try {
      setLoading(true);
      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        coach_id: user.id,
      };


      const { data: newPlan, error: newPlanError } = await upsertPlan(
        supabase,
        planPayload
      );


      if (newPlanError) throw newPlanError;

      if (plan?.id) {
        await deleteExercisePlans(supabase, plan.id);
      }

      // Superset gruplarının set değerlerini sakla
      const supersetSets = new Map();
      selectedExercises.forEach(exercise => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            description: e.exercise.description,
            title: `${e.exercise.title} ${user.full_name}`,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos: [],
            images: [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id ? {
            ...e,
            exercise: { ...e.exercise, id: data[0].id }
          } : e;
        })
      );

      const exercisePlans = processedExercises.map(e => ({
        exercise_id: e.exercise.id,
        plan_id: newPlan[0].id,
        plan_client_id: null,
        order: e.exercise.order,
        notes: e.notes,
        // Eğer superset ise grubun set değerini kullan, değilse kendi set değerini
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup
      }));

      const { data: insertPlan } = await insertExercisePlans(supabase, exercisePlans);

      onSuccess();
      if (onCreateEvent && !isUpdate) onCreateEvent(insertPlan[0]);
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });

  const onSubmitClient = handleSubmit(async (data) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join('\n'));
      return;
    }

    try {
      setLoading(true);

      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        coach_id: user.id,
        created_at: plan?.created_at || new Date(),
      };

      const { data: newPlan, error: newPlanError } = await plan_client_upsert(
        supabase,
        planPayload
      );
      const { data: planId } = await getPlanByIdClient(supabase, newPlan[0].id);


      if (newPlanError) throw newPlanError;

      if (plan?.id) {
        await deleteExercisePlanClient(supabase, plan.id);
      }

      // Superset gruplarının set değerlerini sakla
      const supersetSets = new Map();
      selectedExercises.forEach(exercise => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            description: e.exercise.description,
            title: `${e.exercise.title} ${user.full_name}`,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos: [],
            images: [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id ? {
            ...e,
            exercise: { ...e.exercise, id: data[0].id }
          } : e;
        })
      );
      const exercisePlans = processedExercises.map(e => ({
        exercise_id: e.exercise.id,
        plan_id: planId[0].exercise_plans[0].plan_id,
        plan_client_id: plan.id,
        order: e.exercise.order,
        notes: e.notes,
        // Eğer superset ise grubun set değerini kullan, değilse kendi set değerini
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup
      }));

      const { data: insertPlan } = await insertExercisePlans(supabase, exercisePlans);

      onSuccess();
      if (onCreateEvent) onCreateEvent(insertPlan[0]);
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Paper
      sx={{
        backgroundColor: "#F1F3F4",
        borderRadius: isvisible ? "0px 0px 24px 24px" : "24px",
        border: "none",
        boxShadow: "unset",
        minHeight: isvisible ? "555px" : "684px"
      }}
    >
      <Box p={4} sx={{ paddingBottom: "0px" }}>
        <form onSubmit={isvisible && !onCreateEvent && isUpdate ? onSubmitClient : onSubmit}>
          <Box pb={3} display={"flex"} alignItems={"baseline"} >
            {isvisible ?
              <Button startIcon={<LeftArrow />} onClick={handleClose} sx={{
                fontSize: "12px", textTransform: "none", ".MuiButton-icon": {
                  marginRight: "3px"
                }
              }}>
                <span style={{ marginTop: "1px" }}>
                  Back
                </span>
              </Button> : <Typography sx={{ fontSize: { xl: "16px", lg: "16px", md: "13px", sm: "13px", xs: "13px" }, fontWeight: "600 !important", color: "#2998E9" }}>{isUpdate ? "Update Training Plan" : "Create New Training Plan"}</Typography>}
            {isvisible ? <Button
              sx={button2Style}
              disabled={loading || selectedExercises.length === 0}
              type="submit"
            >
              Save changes
            </Button> : <Button onClick={handleClose} startIcon={<CloseIcon />} size="small" sx={close}></Button>}
          </Box>
          <Box sx={{ mt: 3 }}>
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Plan name
            </InputLabel>
            <TextField
              {...register("name")}
              placeholder="Plan name"
              required
              sx={formtextfieldStyle}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mt: 3 }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Overview
            </InputLabel>
            <TextField
              {...register("overview")}
              variant="outlined"
              type="textarea"
              placeholder="Overview"
              minRows={4}
              multiline
              sx={formDescriptionStyle}
              fullWidth
              inputProps={{ maxLength: 1000 }}
            />
          </Box>
          <Box mt={1}>
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Exercises
            </InputLabel>
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>

                    {groupedExercises.map((group, groupIndex) => (


                      <Box
                        key={groupIndex}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          ...(group.type === 'superset' && {
                            borderRadius: '8px',
                            position: 'relative',
                          }),
                        }}
                      >

                        {(group.type === 'superset' || group.exercises.length > 1) && (
                          <>
                            <Typography
                              sx={{
                                position: 'absolute',
                                top: '45%',
                                left: '10px',
                                transform: 'translateY(-50%)',
                                fontSize: '12px',
                                color: '#666',
                                fontWeight: 600,
                                zIndex: 1000,
                                cursor: "pointer"
                              }}
                            >
                              <DragDropIcon />
                            </Typography>
                            {group.type === 'superset' && (
                              <TextField
                                value={`x${group.sets || ""}`}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(/^x/, '');
                                  setSelectedExercises((prevExercises) => {
                                    return prevExercises.map(ex => {
                                      if (ex.supersetGroup === group.exercises[0].exercise.supersetGroup) {
                                        return { ...ex, sets: newValue };
                                      }
                                      return ex;
                                    });
                                  });
                                }}
                                type="text"
                                sx={{
                                  ...formtextfiel2dStyle,
                                  position: 'absolute',
                                  left: '20px',
                                  top: '0',
                                  height: '90%',
                                  borderRadius: "40px",
                                  width: '50px',
                                  zIndex: 1000,
                                  '& .MuiInputBase-root': {
                                    height: '100%',
                                    minWidth: '50px',
                                    minHeight: '40px'
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 8px',
                                    textAlign: 'center',
                                    minWidth: '34px',
                                    minHeight: '40px'
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          </>
                        )}

                        {group.exercises.map(({ exercise, index }) => (
                          <Draggable
                            key={`${groupIndex}-${index}`}
                            draggableId={`${groupIndex}-${index}`}
                            index={index}
                            type={group.type === 'superset' ? 'group' : 'normal'}
                          >
                            {(provided) => (
                              <>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    marginBottom: '8px',
                                    marginLeft: group.type === 'superset' ? '90px' : '0',
                                    '& .MuiAutocomplete-root': {
                                      width: 'calc(100% - 120px)',
                                    }
                                  }}
                                >

                                  <>
                                    <Typography sx={{ padding: "5px" }}>
                                      <DragDropIcon />
                                    </Typography>
                                    {!exercise.isSuperset && (
                                      <TextField
                                        value={`x${exercise.sets || ""}`}
                                        onChange={(e) => {
                                          const newValue = e.target.value.replace(/^x/, '');
                                          setSelectedExercises((prevSets) => {
                                            const updatedSets = [...prevSets];
                                            updatedSets[index].sets = newValue;
                                            return updatedSets;
                                          });
                                        }}
                                        type="text"
                                        sx={{
                                          ...formtextfiel2dStyle,
                                          '& .MuiInputBase-root': {
                                            minWidth: '50px',
                                            minHeight: '40px'
                                          },
                                          '& .MuiOutlinedInput-input': {
                                            padding: '0 8px',
                                            textAlign: 'center',
                                            minWidth: '34px',
                                            minHeight: '40px'
                                          }
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  </>


                                  <Autocomplete
                                    options={fillexercise}
                                    sx={{
                                      ...formtextselectStyle,
                                      marginLeft: !exercise.isSuperset ? '40px' : '0',
                                    }}
                                    value={exercise.exercise || null}
                                    onChange={(e, selectedOption) => {
                                      if (selectedOption) {
                                        setSelectedExercises((prevExercises) => {
                                          const updatedExercises = [...prevExercises];
                                          updatedExercises[index].exercise = {
                                            ...selectedOption,
                                            order: index + 1
                                          };
                                          return updatedExercises;
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder="Search Exercise" />
                                    )}
                                    getOptionLabel={(option) => option.title || ""}
                                    getOptionKey={(option) => option.id}
                                    PaperComponent={(props) => (
                                      <Paper {...props} sx={{ borderRadius: "16px", backgroundColor: "#FBFBFB" }} />
                                    )}
                                    ListboxProps={{
                                      sx: {
                                        '& .MuiAutocomplete-option': {
                                          color: "#525A65",
                                          fontSize: "14px",
                                          borderBottom: '1px solid #CECECE', // Add a line between options
                                          padding: '10px 10px', // Add space on the right and left
                                          margin: "0px 10px",
                                          '&:last-child': {
                                            borderBottom: 'none' // Remove the line after the last option
                                          }
                                        }
                                      }
                                    }}
                                    popupIcon={
                                      <div style={{ display: 'flex', width: "15px", height: "15px", alignItems: 'center', paddingRight: "10px !important", pointerEvents: 'none' }}>
                                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z" fill="#DAD9D8" />
                                        </svg>
                                      </div>
                                    }
                                  />
                                  <TextField
                                    sx={formtextfiel3dStyle}
                                    value={exercise.notes?.substring(0, 40) || ""}
                                    multiline
                                    minRows={1}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setSelectedExercises((prevNotes) => {
                                        const updatedNotes = [...prevNotes];
                                        updatedNotes[index].notes = newValue;
                                        return updatedNotes;
                                      });
                                    }}
                                    placeholder="Add notes"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleDelete(index)}
                                    sx={{ marginLeft: "auto" }}
                                  >
                                    <DeleteIcon width={"20px"} height={"20px"} />
                                  </IconButton>
                                </Box>
                                {shouldShowSupersetIcon(index) && (
                                  <Button onClick={() => addSuperSet(index)} sx={{ mb: 1 }}>
                                    <SuperSetIcon />
                                  </Button>
                                )}
                              </>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box mt={1} width={"100%"} height={"58px"}>
            <Button
              startIcon={<AddIcon />}
              sx={button1Style}
              onClick={handleAddExercise}
            >
              Add exercise
            </Button>
          </Box>
          {!isvisible && <Box width={"100%"} height={"80px"} sx={{ marginTop: '23%' }}>
            <Button
              type="submit"
              disabled={loading || selectedExercises.length === 0}
              sx={CreateUpdateStyle}
            >
              {isUpdate ? "Update" : "Create"}
            </Button>
          </Box>}
        </form >
      </Box >
    </Paper >
  );
};

TrainingPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  exercises: PropTypes.array,
  plan: PropTypes.object,
  isvisible: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
};

