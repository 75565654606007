export const buttonStyle = {
    color: "#525A65",
    border: "solid 1px",
    borderRadius: "30px",
    fontSize: "11px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    marginBottom: "20px ",
    cursor: "pointer",
    ".buttonCal:hover": {
        backgroundColor: "#525A65",
        color: "white"
    },
    lineHeight: "1",
    height: "25px",
    textTransform: "none"
};

export const buttonStyle2 = {
    color: "#525A65",
    border: "solid 1px",
    borderRadius: "30px",
    fontSize: "12px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    maxWidht: "121px",
    cursor: "pointer",
    ".buttonCal:hover": {
        backgroundColor: "unset",
        color: "#525A65"
    },
    height: "26px",
    lineHeight: "1",
    textTransform: "none"
};

export const buttonStyle3 = {
    color: "#EC0B43",
    border: "solid 1px",
    lineHeight: "1",
    borderRadius: "30px",
    fontSize: "12px",
    backgroundColor: "unset",
    padding: "6px 9px 6px 9px",
    maxWidht: "72px",
    cursor: "pointer",
    ".buttonCal:hover": {
        backgroundColor: "unset",
        color: "#EC0B43"
    },
    height: "26px",
    textTransform: "none"
};


export const close = {
    marginLeft: "auto",
    color: "#525A65",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    padding: "13px",
    borderRadius: "100% !important",
    minWidth: "37px !important",
    fontSize: "12px",
    border: "0px !important",
    lineHeight: "1",
    backgroundColor: "#EEEEEE",
    "&:hover": {
        color: "#525A65",
        backgroundColor: "#dddddd"
    },
    ".MuiButton-icon": {
        margin: "0px !important"
    }
}

export const buttonStyle4 = {
    marginLeft: "auto",
    color: "#E67B02",
    border: "solid 1px",
    fontSize: "12px",
    textTransform: "none",
    paddingRight: "10px",
    lineHeight: "1",
    maxHeigh: "26px",
    borderRadius: "20px",
    "&:hover": {
        color: "#c96b00"
    },

}