export const button1Style = {
    marginLeft: "15px",
    color: "#02BD36",
    border: "solid 1px",
    fontSize: "12px",
    textTransform: "none",
    paddingRight: "10px",
    maxHeight: "26px",
    letterSpacing: "0em",
    lineHeight: "1 !important",
    borderRadius: "30px",
}

export const button2Style = {
    marginLeft: "auto",
    color: "#525A65",
    border: "solid 1px",
    fontSize: "12px",
    textTransform: "none",
    paddingRight: "10px",
    maxHeight: "26px",
    letterSpacing: "0em",
    lineHeight: "1 !important",
    borderRadius: "30px",

}

export const button3Style = {
    marginLeft: "auto",
    color: "#2998E9",
    border: "solid 1px",
    fontSize: "12px",
    textTransform: "none",
    paddingRight: "10px",
    maxHeight: "26px",
    letterSpacing: "0em",
    lineHeight: "1 !important",
    borderRadius: "30px",
}


export const searchStyle = {
    ".MuiFormLabel-root": {
        fontWeight: "600 !important",
        paddingLeft: "7px",
        fontSize: "12px",
    },
    ".MuiInputBase-root": {
        borderRadius: "30px",
        height: "40px",
    },
    ".css-1ut4ayd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        padding: "0",
        fontSize: "15px",
    },
    ".MuiOutlinedInput-notchedOutline": {
        bottom: "-3px",
        top: "-2px",
        border: "solid 1px #525A65",
    },
    ".MuiInputBase-input": {
        fontWeight: "600 !important",
        fontSize: "12px",
        color: "#525A65 !important",
        padding: "10px 24px",
        marginTop: "4px"
    }
}


export const SortIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="14" rx="4" fill="#525A65" />
            <path d="M5.182 8.9751L5.17725 5.11908L6.26006 6.20475C6.44053 6.39194 6.74447 6.39194 6.93444 6.21411C7.12441 6.03629 7.12441 5.73679 6.94394 5.54961L5.04427 3.64032C4.95879 3.54672 4.83531 3.49993 4.70233 3.49993C4.57885 3.49993 4.45537 3.54672 4.36039 3.64032L2.47022 5.54961C2.28976 5.73679 2.28976 6.02693 2.47972 6.21411C2.66969 6.39194 2.96414 6.39194 3.1541 6.20475L4.22741 5.11908L4.23216 8.9751L5.182 8.9751Z" fill="#FBFBFB" />
            <path d="M8.82582 5.47754L8.83056 9.33356L7.74775 8.24788C7.56729 8.0607 7.26334 8.0607 7.07337 8.23852C6.88341 8.41635 6.88341 8.71585 7.06387 8.90303L8.96354 10.8123C9.04903 10.9059 9.1725 10.9527 9.30548 10.9527C9.42896 10.9527 9.55244 10.9059 9.64742 10.8123L11.5376 8.90303C11.7181 8.71585 11.7181 8.42571 11.5281 8.23852C11.3381 8.0607 11.0437 8.0607 10.8537 8.24788L9.7804 9.33356L9.77565 5.47754H8.82582Z" fill="#FBFBFB" />
        </svg>
    )
}



export const MenuIcon = () => {
    return (<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#525A65" />
        <circle cx="8" cy="2" r="2" transform="rotate(-90 8 2)" fill="#525A65" />
        <circle cx="14" cy="2" r="2" transform="rotate(-90 14 2)" fill="#525A65" />
    </svg>)
}

