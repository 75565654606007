

export const buttonStyle = {
    marginLeft: "auto",
    color: "#A080E8",
    border: "solid 1px",
    textTransform: "none",
    maxHeight: "26px",
    fontSize: "12px",
    lineHeight: "1",
    borderRadius: "20px",
}

export const AutoCompleteStyle = {
    ".MuiFormLabel-root": {
        fontWeight: "600 !important",
        paddingLeft: "7px",
        fontSize: "12px",
    },
    ".MuiInputBase-root": {
        borderRadius: "30px",
        height: "40px",
    },
    ".css-1ut4ayd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        padding: "0",
        fontSize: "15px",
    },
    ".MuiOutlinedInput-notchedOutline": {
        bottom: "-3px",
        top: "-2px",
        border: "solid 1px #525A65",
    },
    ".MuiInputBase-input": {
        fontWeight: "600 !important",
        fontSize: "12px",
        color: "#525A65 !important",
        padding: "10px 24px",
        marginTop: "4px"
    }
}