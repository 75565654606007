import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import { sideBarConfig } from "./sideBar.config";
import { useAuth } from "../../providers/AuthContextProvider";
import { ProtectedAdmin } from "../../routes/Admin/ProtectedAdmin";
import {
  CustomizedListItemButton,
  CustomizedListItemIcon,
  CustomizedListItemText,
  CustomizedListItem,
  CustomizedToolbar,
} from "./style";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';



export const drawerWidth = 300;

export const SideBar = ({
  drawerOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  handleDrawerToggle
}) => {
  const { session, user } = useAuth();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    handleDrawerClose(); // Close the drawer after clicking
  };
  if (!session) {
    return null;
  }


  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List>
        {sideBarConfig.map((item, index) =>
          item.name === "Admin" ? (
            ProtectedAdmin(user) ? (
              <CustomizedListItem key={item.name}>
                <CustomizedListItemButton
                  component={Link}
                  disabled={window.location.pathname === "/coach-onboarding" ? true : false}
                  to={item.path}
                  onClick={() => handleItemClick(item.name)}
                  sx={{
                    backgroundColor: window.location.pathname === item.path ? '#013B12' : 'transparent', // Change color here
                    "path": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    "circle": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    "rect": {
                      fill: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                    ".css-1l6tx9d .MuiTypography-root": {
                      color: window.location.pathname === item.path ? "#02BD36 !important" : '013B12 !important'
                    },
                  }}
                >
                  <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>
                  <CustomizedListItemText primary={item.name} />
                </CustomizedListItemButton>
              </CustomizedListItem>
            ) : (
              <div key={item.name}></div>
            )
          ) : (
            <CustomizedListItem key={item.name}>
              <CustomizedListItemButton
                component={Link}
                to={item.path}
                onClick={() => handleItemClick(item.name)}
                sx={{
                  backgroundColor: window.location.pathname === item.path ? '#013B12' : selectedItem === item.name ? '#013B12' : 'transparent', // Change color here
                  "path": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  "circle": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  "rect": {
                    fill: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                  ".MuiTypography-root": {
                    color: window.location.pathname === item.path ? "#02BD36 !important" : selectedItem === item.name ? "#02BD36 !important" : '013B12 !important'
                  },
                }}
              >
                <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>
                <CustomizedListItemText primary={item.name} />
              </CustomizedListItemButton>
            </CustomizedListItem>
          )
        )}
      </List>
    </Box >
  );

  return (
    <>
      <AppBar
        sx={{
          width: { sm: `calc(100% - 0px)` },
          display: "none",
          // ml: {sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: "#FBFBFB", height: "90px" }}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={8}>
              <img
                src={require("../../assets/LogoDasboard.png")}
                alt={require("../../assets/LogoDasboard.png")}
                width={218}

              />
            </Grid>
            <Grid item xs={4} sx={{ alignItems: "center", display: "flex", justifyContent: "end" }}>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5C0 2.23858 2.23858 0 5 0H27C29.7614 0 32 2.23858 32 5V27C32 29.7614 29.7614 32 27 32H5C2.23858 32 0 29.7614 0 27V5Z" fill="#013B12" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.39941 9.6C6.39941 8.71634 7.11576 8 7.99941 8H23.9994C24.8831 8 25.5994 8.71634 25.5994 9.6C25.5994 10.4837 24.8831 11.2 23.9994 11.2H7.99941C7.11576 11.2 6.39941 10.4837 6.39941 9.6ZM6.39941 16C6.39941 15.1163 7.11576 14.4 7.99941 14.4H23.9994C24.8831 14.4 25.5994 15.1163 25.5994 16C25.5994 16.8837 24.8831 17.6 23.9994 17.6H7.99941C7.11576 17.6 6.39941 16.8837 6.39941 16ZM7.99941 20.8C7.11576 20.8 6.39941 21.5163 6.39941 22.4C6.39941 23.2837 7.11576 24 7.99941 24H23.9994C24.8831 24 25.5994 23.2837 25.5994 22.4C25.5994 21.5163 24.8831 20.8 23.9994 20.8H7.99941Z" fill="#02BD36" />
                </svg>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      < Drawer
        variant="temporary"
        open={drawerOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }
        }
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <CustomizedToolbar>
          <img
            src={require("../../assets/LogoDasboard.png")}
            alt={require("../../assets/LogoDasboard.png")}
            width={218}
          />
        </CustomizedToolbar>
        {drawer}
      </ Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "0px",
          },
          display: { xs: "none", sm: "none", md: "block" },
        }}
        open
      >
        <CustomizedToolbar>
          <img className="logo-coach"
            src={require("../../assets/LogoDasboard.png")}
            alt={require("../../assets/LogoDasboard.png")}
            loading="lazy"
            width={218}
          />
        </CustomizedToolbar>
        {drawer}
      </Drawer>
    </ >
  );
};
