




export const Blog = () => {
    return (
        <>
            <div id="bh-posts">

            </div>
        </>

    )
}