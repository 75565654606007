import { useEffect, useState } from "react";
import { TrainingPlanModal } from "../../components/TrainingPlanModal/TrainingPlanModal";
import {
    Box,
    Button,
    IconButton,
    Paper,
    TextField,
    Typography,
    List, ListItem, ListItemText, ListItemIcon,
} from "@mui/material";
import { useSupabase } from "../../providers/AuthContextProvider";
import {
    deletePlan,
    getPlansByCoachId,
} from "../../services/query";
import Divider from "@mui/material/Divider";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { MenuIcon, searchStyle, button3Style } from "./style"


export const PlanList = ({ user }) => {
    const [plans, setPlans] = useState([]);
    const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [tpSearch, setTpSearch] = useState("");
    const supabase = useSupabase();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index); // Set the index of the clicked plan
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null); // Close the menu
    };
    const loadPlans = () => {
        getPlansByCoachId(supabase, user.id).then(({ data }) => {
            setPlans(data || []);
        });
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        loadPlans();
    }, [user]);

    return (
        <>
            <Paper sx={{ backgroundColor: "#FBFBFB", borderRadius: "24px", height: "600px", border: "none", display: trainingPlanOpen ? "none" : "block", boxShadow: "unset" }}>
                <Box p={3} pt={4} sx={{ height: "144px" }}>
                    <Box pb={2} display={"flex"} alignItems={"flex-end"} >
                        <Typography sx={{ fontSize: { xl: "16px", lg: "16px", md: "13px", sm: "13px", xs: "13px" }, fontWeight: "600 !important", color: "#525A65" }}>My training plans</Typography>
                        <Button
                            sx={button3Style}
                            onClick={() => setTrainingPlanOpen(true)}
                        >
                            + Add new training plan
                        </Button>
                    </Box>

                    <TextField
                        id="outlined-search"
                        label="Search plan by name..."
                        type="search"
                        fullWidth
                        value={tpSearch}
                        sx={searchStyle}
                        onChange={(e) => setTpSearch(e.target.value)}
                    />
                </Box>
                <Divider sx={{ marginTop: "22px" }} />
                <List sx={{ pl: 2, pr: 2, pt: 1, maxHeight: "432px", overflow: "auto" }}>
                    {plans.filter((plan) => plan.name.toLowerCase().includes(tpSearch.toLowerCase()))
                        .map((plan, i) => (
                            <ListItem key={i} sx={{
                                padding: '12px',
                                backgroundColor: '#F1F3F4',
                                borderRadius: "8px",
                                marginBottom: '8px',
                            }}>
                                <ListItemText
                                    primary={
                                        <Box display={"flex"} flexDirection={"column"} >
                                            <Typography gutterBottom sx={{ ml: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "600 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.name}</Typography>
                                            <Typography gutterBottom sx={{ ml: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "400 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.overview?.substring(0, 150)}</Typography>
                                            <Typography gutterBottom sx={{ ml: 1, color: "#525A65", fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" }, fontWeight: "400 !important", lineHeight: "1 !important", letterSpacing: "0 !important" }}>{plan.exercise_plans.map((t) => t.exercises?.title + ",")}</Typography>
                                        </Box>
                                    }
                                />
                                <IconButton
                                    id="basic-button"
                                    aria-controls={openMenuIndex === i ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    sx={{ padding: "16px 10px 16px 10px !important", }}
                                    aria-expanded={openMenuIndex === i ? 'true' : undefined}
                                    onClick={(event) => handleClick(event, i)} // Pass the index
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenuIndex === i}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        setTrainingPlanOpen(true);
                                        setSelectedPlan(plan);
                                        handleClose()
                                    }}>View/Edit</MenuItem>
                                    <MenuItem >Duplicate</MenuItem>
                                    <MenuItem onClick={async () => {
                                        if (
                                            window.confirm(
                                                `Deleting training plan: "${plan.name}". Please confirm.`
                                            )
                                        ) {
                                            await deletePlan(supabase, plan.id);
                                            loadPlans();
                                        }
                                        handleClose()
                                    }}>Delete</MenuItem>
                                </Menu>
                            </ListItem>
                        ))}
                </List>
            </Paper>
            {trainingPlanOpen && (
                <TrainingPlanModal
                    open={trainingPlanOpen}
                    plan={selectedPlan}
                    handleClose={() => {
                        setTrainingPlanOpen(false);
                        setSelectedPlan(null);
                    }}
                    onSuccess={() => loadPlans()}
                />
            )}
        </>

    )
}