import { Box, Button, TextField, Typography, Paper, Autocomplete } from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { getCoachById, updateCoach } from "../../services/query";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Typography1style, Typography2style, Typography3style, subsStyle, formtextfieldStyle, formautodStyle1, formautodStyle2, submitStyle, formDateFieldStyle, signoutStyle } from "./style";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { sortedCountries } from "../../components/countrycode/countrycode"
import dayjs from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from "@mui/material/InputAdornment";


export const AccountSettings = () => {
  const { register, handleSubmit, reset, control } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const [areaCode, setAreaCode] = useState("");
  const { user, signOut } = useAuth();
  const supabase = useSupabase();
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const getCoach = async () => {
    setLoading(true);
    const { data } = await getCoachById(supabase, user.id);
    if (data) {
      reset(data[0]);
    }
  };

  const getSubStatus = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const { data } = await supabase.functions.invoke("st-sub-status");
        setSub(data);
      } catch (error) {
        alert(error.message);
      }
      setLoading(false);
    }, 1000);
  };

  const onSubmit = handleSubmit((data) => {
    setSubmitLoading(true);
    if (data.country_code === null) {
      setSubmitLoading(false)
      return alert("Please fill the code")
    }

    updateCoach(
      supabase,
      { ...data, full_name: `${data.first_name} ${data.last_name}` },
      user.id
    ).then((sev) => { setSubmitLoading(false) })
  });

  useEffect(() => {
    if (user) {
      getCoach();
      getSubStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  if (loading) {
    return <PageContainer>Loading...</PageContainer>;
  }
  return (
    <>
      <PageContainer>
        <Paper variant="outlined" sx={{ borderRadius: "24px", backgroundColor: "#FBFBFB !important", height: "980px", border: "none" }}>
          <Box sx={{ padding: "40px", maxWidth: "600px" }}>
            <Typography variant="subtitle" sx={Typography1style}>Settings</Typography>
            {sub && (
              <Box mt={3} sx={{ marginTop: "34px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle" sx={Typography2style}>
                      Subscription Status:{" "}
                      <Typography variant="subtitle" sx={Typography3style}>{sub && sub.isSubscribed ? "Active" : "No subscription"}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ paddingTop: "0px !important", display: "flex" }}>

                    {sub && sub.isSubscribed && (
                      <Button
                        sx={subsStyle}
                        onClick={async () => {
                          setLoading(true);
                          try {
                            await supabase.functions.invoke("st-cancel-sub");
                            getSubStatus();
                          } catch (error) {
                            alert(error.message);
                          }
                          setLoading(false);
                        }}
                        disabled={loading}
                      >
                        Cancel Subscription
                      </Button>
                    )}
                    {!sub.isSubscribed && (
                      <Button
                        sx={subsStyle}
                        disabled={loading}
                        onClick={async () => {
                          setLoading(true);
                          try {
                            const { data } = await supabase.functions.invoke(
                              "st-checkout",
                              {
                                body: {
                                  priceId: process.env.REACT_APP_STRIPE_PRICE,
                                  baseUrl: window.location.origin,
                                },
                              }
                            );
                            window.location = data.url;
                          } catch (error) {
                            alert(error.message);
                          }
                          setLoading(false);
                        }}
                      >
                        Subscribe
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
            <br />
            <form action="">
              <Box sx={{ mt: 3 }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  First name
                </InputLabel>
                <TextField
                  {...register("first_name")}
                  placeholder="First name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Surname
                </InputLabel>
                <TextField
                  {...register("last_name")}
                  placeholder="Surname"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  control={control}
                  name="dob"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateField']} sx={{
                          ".css-10o2lyd-MuiStack-root": {
                            paddingTop: "0px !important",
                          },
                        }}>
                          <Box sx={{ mt: 3, width: "100%" }}>
                            <InputLabel shrink htmlFor="bootstrap-input" sx={{
                              paddingLeft: "24px",
                              color: "#838F99",
                              fontWeight: "400 !important"
                            }}>
                              Date of birth
                            </InputLabel>
                            <DateField
                              defaultValue={dayjs(new Date(field.value ? field.value : null))}
                              format="LL"
                              sx={formDateFieldStyle}
                              inputRef={field.ref}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              fullWidth
                            />
                          </Box>
                        </DemoContainer>
                      </LocalizationProvider>
                    );
                  }}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  City
                </InputLabel>
                <TextField
                  {...register("city")}
                  placeholder="City Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Country
                </InputLabel>
                <TextField
                  {...register("country")}
                  placeholder="country Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              {/* <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sx={{ paddingRight: "16px !important" }}>
                    <InputLabel shrink htmlFor="bootstrap-input" sx={{
                      paddingLeft: "24px",
                      color: "#838F99",
                      fontWeight: "400 !important"
                    }}>
                      Code
                    </InputLabel>
                    <Controller
                      name="country_code"
                      control={control}
                      defaultValue="" // Set default value if needed
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={sortedCountries}
                          getOptionLabel={(option) => `+(${option.phone})`}
                          onChange={(event, value) => {
                            field.onChange(value); // Set the country code
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="+00" sx={{
                              ".css-vubbuv": {
                                display: "none"
                              },
                              "&:hover": {
                                ".MuiInputAdornment-root": {
                                  display: "none"
                                }
                              },
                              "&:focus": {
                                ".MuiInputAdornment-root": {
                                  display: "none"
                                }
                              },
                              "&:active": {
                                ".MuiInputAdornment-root": {
                                  display: "none"
                                }
                              }
                            }} InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowDownIcon sx={{
                                    display: "inline-block", position: "absolute", marginLeft: "95px", ":hover": {
                                      display: "none"
                                    }
                                  }} />
                                </InputAdornment>
                              )
                            }} />
                          )}
                          sx={formautodStyle1}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} sx={{
                    paddingLeft: "0px !important",
                    ".css-cmlpbm": {
                      widht: "100% !important"
                    },
                    ".MuiFormControl-root": {
                      widht: "100% !important"
                    }

                  }}>
                    <InputLabel shrink htmlFor="bootstrap-input" sx={{
                      paddingLeft: "24px",
                      color: "#838F99",
                      fontWeight: "400 !important"
                    }}>
                      Telephone number
                    </InputLabel>
                    <TextField
                      type="number"
                      placeholder="Telephone number"
                      {...register("phone_number")}
                      sx={formautodStyle2}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box> */}
              <Box sx={{ mt: 3 }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  What sport or activity do you coach?
                </InputLabel>
                <TextField
                  {...register("sports_activities")}
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              {/* <Box sx={{ mt: 3 }}>
                <TextField
                  {...register("athlete_types")}
                  label="Athlete Types"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <TextField
                  {...register("athlete_count")}
                  label="Number of athletes"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box> */}

              <br />
              <br />
              <Button
                type="submit"
                sx={submitStyle}
                onClick={onSubmit}
                disabled={submitLoading}
              >
                Submit
              </Button>
              <br />
              <br />
              <Button
                sx={signoutStyle}
                type="submit"
                onClick={signOut}
              >
                Signout
              </Button>
              <br />
            </form>
          </Box>
        </Paper>
      </PageContainer>
    </>
  );
};
