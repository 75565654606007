import { Avatar, Box, Button, Chip, Paper, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { typographyStyle, buttonStyle } from "./style"


export const CoachProfileCard = ({ user, athletes }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Paper variant="outlined" sx={{ borderRadius: "24px", backgroundColor: "#FBFBFB !important", border: "none" }}>
      <Box p={3} pb={1}>
        <Box
          display={"flex"}
          alignItems={"center"}
          mb={3}
          sx={{
            marginBottom: "14px",
            marginTop: "10px",
          }}
        >
          <Avatar
            src={user?.sessionUser?.user_metadata?.avatar_url}
            sx={{ width: "60px", height: "60px" }}
          />

          <Typography
            variant="body1"
            sx={{
              ml: 2,
              color: "#0F0F0F",
              fontWeight: "600 !important",
              fontSize: "24px",
            }}
          >
            <Typography variant="subtitle1" sx={{ color: "#525A65", fontWeight: "600 !important", fontSize: "16px", }}>
              Welcome!
            </Typography>
            {user?.full_name}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          mb={3}
          flexWrap="wrap"
          sx={{
            "& > *": {
              m: 0.8,
            },
            paddingLeft:  9,
            marginBottom: "18px",
          }}
        >
          {/* <img src={require("../../assets/Group 139.png")} /> */}
          {/* <Typography
            variant="subtitle1"
            sx={{
              color: "#525A65",
              fontSize: "12px",
              fontWeight: "600 !important",
            }}
          >
            {user?.city}, {user?.country}
          </Typography> */}
          {/* <img
            src={require("../../assets/Group 141.png")}
            style={{ marginLeft: "25px" }}
          /> */}
          {/* <Typography
            variant="subtitle1"
            sx={{
              color: "#525A65",
              fontSize: "12px",
              fontWeight: "600 !important",
            }}
          >
            {athletes && athletes.length}
          </Typography>
          {
            user?.sports_activities ?
              <Typography
                variant="subtitle1"
                sx={typographyStyle}
              >
                {user?.sports_activities}
              </Typography> : null
          } */}
          <Button href="/settings" sx={buttonStyle}>Edit profile</Button>
        </Box>
      </Box>
    </Paper>
  )
}




