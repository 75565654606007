import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  IconButton,
  TextField,
  Typography,
  Paper,
  Autocomplete,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useForm } from "react-hook-form";
import { SPORT_TYPES } from "../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import {
  getYouTubeVideoId,
  isValidURL,
  isValidYouTubeUrl,
} from "../../utils/validate";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { upsertExercise, get_fill_exercise } from "../../services/query";
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import { VideoIcon, DeleteIcon, CreateUpdateStyle, AddVidoeandImageStyle, close, formDescriptionStyle, formtextselectStyle, formtextfiel2dStyle, formtextfieldStyle } from "./style"

export const ExerciseModal = ({ open, handleClose, exercise, onSuccess }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: exercise || {},
  });

  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [videoText, setVideoText] = useState("");
  const [imageText, setImageText] = useState("");
  const [description, setDescription] = useState("");
  const [Tag, setTags] = useState("");
  const [title, setTitle] = useState([]);
  const [Workout, setWorkout] = useState([]);
  const [Workoutvalue, setWorkoutvalue] = useState(null);
  const [Require, setRequire] = useState([
    { require: true, requires_equipment: "Yes" },
    { require: false, requires_equipment: "No" },
  ]);
  const [RequireValue, setRequireValue] = useState(null);
  const [sportTypes, setSportTypes] = useState([]);
  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    if (!exercise) {
      return;
    }
    setTitle(exercise.title || "");
    setDescription(exercise.description || "");
    setTags(exercise.tags || "");
    setRequireValue(
      exercise.requires_equipment === true
        ? { require: true, requires_equipment: "Yes" } || null
        : { require: false, requires_equipment: "No" } || null
    );
    setVideos(exercise.videos || []);
    setImages(exercise.images || []);
  }, [exercise]);

  useEffect(() => {
    const fetchExercises = async () => {
      const { data: _fillexercise } = await get_fill_exercise(supabase);
      setWorkout(_fillexercise || []);
    };

    fetchExercises();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const id = exercise && exercise.id ? { id: exercise.id } : {};
    // if (!RequireValue) {
    //   setLoading(false);
    //   return alert("Please Select Requires Equipment");
    // }
    if (exercise) {
      const payload = {
        ...id,
        description: description,
        title: title,
        tags: Tag,
        requires_equipment: false,
        videos,
        images,
        coach_id: user.id,
      };
      await upsertExercise(supabase, payload);
    } else {
      if (!Workoutvalue) {
        setLoading(false);
        return alert("Please Select Customize an Exercise");
      }
      const payload = {
        ...id,
        description: description,
        title: title + " " + user.full_name,
        tags: Tag,
        workout: Workoutvalue ? Workoutvalue.title : "",
        // requires_equipment: RequireValue.require == true ? true : false,
        requires_equipment: false,
        videos,
        images,
        coach_id: user.id,
      };
      await upsertExercise(supabase, payload);
    }
    setLoading(false);
    onSuccess();
    handleClose();
  });

  const changeTitle = (newValue) => {
    if (newValue !== null) {
      setTitle(newValue.title === "Custom" ? "" : newValue.title);
      setWorkoutvalue(newValue);
      setDescription(newValue.description);
      setTags(newValue.tags);
      if (newValue.requires_equipment !== null) {
        setRequireValue(
          newValue.requires_equipment === true
            ? { require: true, requires_equipment: "Yes" }
            : { require: false, requires_equipment: "No" }
        );
      } else {
        setRequireValue(null);
      }
    } else {
      setTitle("");
      setWorkoutvalue(null);
      setRequireValue(null);
      setDescription("");
      setTags("");
      setRequire([
        { require: true, requires_equipment: "Yes" },
        { require: false, requires_equipment: "No" },
      ]);
    }
  };

  return (
    <Paper sx={{ backgroundColor: "#F1F3F4", borderRadius: "24px", border: "none", boxShadow: "unset" }}>
      <Box p={4} sx={{ paddingBottom: "0px" }}>
        <Box pb={3} display={"flex"} alignItems={"baseline"} >
          <Typography sx={{ fontSize: { xl: "16px", lg: "16px", md: "13px", sm: "13px", xs: "13px" }, fontWeight: "600 !important", color: "#02BD36" }}>{exercise ? "Update" : "Create New"} Exercise</Typography>
          <Button onClick={handleClose} startIcon={<CloseIcon />} size="small" sx={close}></Button>
        </Box>
        <Box pb={3} >
          <Typography sx={{ fontSize: { xl: "12px", lg: "10px", md: "10px", sm: "9px", xs: "9px" }, fontWeight: "400 !important", color: "#525A65" }}>Exercises are the building blocks of your training plan. Create a new exercise to add to one of your training plans.</Typography>
        </Box>
        <form onSubmit={onSubmit}>
          {exercise ? (
            <></>
          ) : (
            <>
              <Box mb={3}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important"
                }}>
                  Customize an exercise
                </InputLabel>
                <Autocomplete
                  options={Workout}
                  sx={formtextselectStyle}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Customize an exercise"
                    />
                  )}
                  onChange={(e, v) => changeTitle(v)}
                  getOptionLabel={(option) => `${option.title}`}
                  getOptionKey={(option) => option.id}
                />
              </Box>
            </>
          )}

          <Box sx={{ mt: 3 }}>
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Title
            </InputLabel>
            <TextField
              {...register("title")}
              placeholder="Title"
              value={title}
              onChange={(e, v) => setTitle(e.target.value)}
              sx={formtextfieldStyle}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mt: 3 }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Description
            </InputLabel>
            <TextField
              {...register("description")}
              variant="outlined"
              type="textarea"
              placeholder="Description"
              value={description}
              onChange={(e, v) => setDescription(e.target.value)}
              minRows={4}
              multiline
              sx={formDescriptionStyle}
              fullWidth
              inputProps={{ maxLength: 1000 }}
            />
          </Box>
          <Box sx={{ mt: 3 }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Add a YouTube Video
            </InputLabel>
            <TextField
              variant="outlined"
              placeholder="Add a YouTube Video"
              value={videoText}
              onChange={(e) => setVideoText(e.target.value)}
              sx={formtextfiel2dStyle}
            />
            <Button
              sx={AddVidoeandImageStyle}
              onClick={() => {
                if (!isValidYouTubeUrl(videoText)) {
                  setVideoText("");
                  return alert("Invalid youtube url");
                }

                setVideos([
                  ...videos,
                  {
                    url: videoText,
                    id: getYouTubeVideoId(videoText),
                  },
                ]);
                setVideoText("");
              }}
            >
              Add
            </Button>

          </Box>
          <Grid2 container spacing={2} sx={{ mt: 2 }}>
            {videos.map((v, i) => (
              <Grid2 key={i} xs={4} sx={{ display: 'flex', alignItems: 'center', padding: '0', margin: '0', width: "auto", marginRight: "10px", marginBottom: "15px" }}>
                <IconButton
                  color="error"
                  sx={{
                    background: "rgba(255, 255, 255, .7) !important",
                    marginRight: "8px", // Space between the button and the video thumbnail
                    "&:hover": {
                      background: "rgba(255, 255, 255, 1) !important",
                    },
                  }}
                  onClick={() =>
                    setVideos(videos.filter((vid) => v.id !== vid.id))
                  }
                >
                  <DeleteIcon width={"20px"} height={"20px"} />
                </IconButton>
                <div style={{ width: '150px', height: '84px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a style={{ cursor: "pointer", position: 'relative', width: '100%', height: '100%' }} >
                    <img
                      src={`https://img.youtube.com/vi/${v.id}/hqdefault.jpg`}
                      alt='Exercise video'
                      style={{
                        width: "150px", // Fixed width
                        height: "84px", // Fixed height
                        objectFit: "cover", // Ensures the image covers the area without black spaces
                        borderRadius: "16px",
                        transition: "transform 250ms ease-in-out"
                      }}
                      className="responsive-img"
                    />
                    <VideoIcon />
                  </a>
                </div>
              </Grid2>
            ))}
          </Grid2>
          <Box sx={{ mt: 4, }} >
            <InputLabel shrink htmlFor="bootstrap-input" sx={{
              paddingLeft: "24px",
              color: "#838F99",
              fontWeight: "400 !important"
            }}>
              Add images
            </InputLabel>
            <TextField
              variant="outlined"
              placeholder="Add images"
              value={imageText}
              onChange={(e) => setImageText(e.target.value)}
              sx={formtextfiel2dStyle}
            />
            <Button
              sx={AddVidoeandImageStyle}
              onClick={() => {
                if (!isValidURL(imageText)) {
                  setImageText("");
                  return alert("Invalid url");
                }

                setImages([...images, imageText]);
                setImageText("");
              }}
            >
              Add
            </Button>

          </Box>
          <Grid2 container spacing={2} mt={1}>
            {images.map((image, i) => (
              <Grid2 key={i} xs={4} sx={{ display: 'flex', alignItems: 'center', padding: '0', margin: '0', width: "auto", marginRight: "10px", marginBottom: "20px" }}>
                <IconButton
                  color="error"
                  sx={{
                    "&:hover": {
                      background: "rgba(255, 255, 255, 1) !important",
                    },
                    marginRight: '2px', // Reduced space between the button and the card
                  }}
                  onClick={() =>
                    setImages(images.filter((img) => image !== img))
                  }
                >
                  <DeleteIcon width={"14px"} height={"14px"} />
                </IconButton>
                <Card
                  sx={{
                    width: '90px',
                    height: '50px',
                    background: `url(${image}) no-repeat center center`,
                    backgroundSize: 'cover',
                    display: 'flex',
                    borderRadius: "8px",
                    alignItems: 'flex-end',
                    margin: '0', // Ensure no margin on the card
                  }}
                >
                  <CardActions
                    disableSpacing
                    sx={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'end',
                    }}
                  >
                    {/* Add any additional actions or content here if needed */}
                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>
          <Box mt={4} width={"100%"} height={"80px"}>
            <Button
              type="submit"
              disabled={loading}
              sx={CreateUpdateStyle}
            >
              {exercise ? "Update" : "Create"}
            </Button>
          </Box>
        </form>
      </Box>
    </Paper >
  );
};



