export const searchstyle = {
    ".MuiFormLabel-root": {
        paddingLeft: "7px",
        fontSize: "12px",
        color: "#525A65 !important"
    },
    ".MuiInputBase-root": {
        borderRadius: "30px",
        height: "40px",
    },
    ".css-1ut4ayd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
        padding: "0",
        fontSize: "15px",
    },
    ".MuiOutlinedInput-notchedOutline": {
        bottom: "-3px",
        top: "-2px",
        border: "solid 1px #525A65 !important",
    },
    ".MuiInputBase-input": {
        fontSize: "12px",
        color: "#525A65 !important",
        padding: "10px 24px",
        marginTop: "4px"
    }
}

export const addformstyle = {
    marginLeft: "auto",
    color: "#2998E9",
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "30px",
    marginLeft: "auto",
    fontSize: "12px",
    padding: "6px 10px 6px 5px",
    lineHeight: "1",
    ".MuiSvgIcon-root": {
        fontSize: "12px !important",
        paddingLeft: "2px",
    },
    "&:hover": {
        color: "#2998E9",
        backgroundColor: "unset"
    },
    ".MuiButton-icon": {
        widht: "10px",
        paddingLeft: "3px",
        marginRight: "0px"
    },
}
export const editstyle = {
    marginLeft: "auto",
    color: "#525A65",
    mr: 2,
    border: "solid 1px",
    textTransform: "none",
    paddingRight: "10px",
    borderRadius: "30px",
    marginLeft: "auto",
    fontSize: "10px",
    padding: "4px",
    minWidth: "37px",
    lineHeight: "1",
    "&:hover": {
        color: "#525A65",
        backgroundColor: "unset"
    },
}
export const Typography1 = {
    fontSize: "16px", fontWeight: "600 !important", color: "#525A65"
}

export const paper = {
    borderRadius: "24px", maxHeight: "880px", width: "100%", backgroundColor: "#FBFBFB !important", border: "none"
}

export const table = {
    padding: "0px 20px 0px 20px", borderSpacing: "0px 11px",
    "tr td:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px"
    },
    "tr td:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px"
    }
}



export const Svgedit = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_757_256)">
                <path
                    d="M17.4989 17.4991H2.49895V2.4991H8.78105C8.89684 2.4991 9.01053 2.45278 9.09263 2.37068L10.7137 0.749626C10.9916 0.471731 10.7958 -0.00195312 10.4021 -0.00195312H2.50105C1.12 0.000152138 0 1.12015 0 2.4991V17.4991C0 18.8802 1.12 20.0002 2.50105 20.0002H17.5011C18.8821 20.0002 20.0021 18.8802 20.0021 17.4991V9.59805C20.0021 9.20647 19.5284 9.01068 19.2505 9.28647L17.6295 10.9075C17.5474 10.9896 17.5011 11.1012 17.5011 11.2191V17.5012L17.4989 17.4991Z"
                    fill="#525A65"
                />
                <path
                    d="M5 10.3343V14.1932C5 14.6395 5.36 14.9995 5.80842 14.9995H9.66737C9.8821 14.9995 10.0863 14.9153 10.2379 14.7637L19.6905 5.31111C19.8632 5.13848 19.8632 4.86059 19.6905 4.68796L15.3116 0.311114C15.1389 0.138483 14.8611 0.138483 14.6884 0.311114L5.23579 9.76164C5.08421 9.91322 5 10.1195 5 10.3322V10.3343Z"
                    fill="#525A65"
                />
            </g>
            <defs>
                <clipPath id="clip0_757_256">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const Svgdelete = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#EC0B43" />
            <g clip-path="url(#clip0_1183_951)">
                <path d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z" fill="#F1F3F4" />
            </g>
            <defs>
                <clipPath id="clip0_1183_951">
                    <rect width="10" height="10" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </svg>

    );
};