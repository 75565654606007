import { useEffect, useState } from "react";
import { ExerciseModal } from "../../components/ExerciseModal/ExerciseModal";
import {
    Box,
    Button,
    IconButton,
    Paper,
    TextField,
    Typography,
    List, ListItem, ListItemText, ListItemIcon,
} from "@mui/material";
import { useSupabase } from "../../providers/AuthContextProvider";
import {
    deleteExercise,
    getExercisesByCoachId,
} from "../../services/query";
import Divider from "@mui/material/Divider";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { MenuIcon, SortIcon, searchStyle, button2Style, button1Style } from "./style"
import { TPModal } from "../../components/TPDisplay/OpenmodelTP";
import { FirstVideoImage } from "../../components/TPDisplay/TPDisplay";


export const ExerciseList = ({ user }) => {
    const supabase = useSupabase();
    const [eSearch, setESearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [exercises, setExercises] = useState([]);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [showCustom, setShowCustom] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [openModal, setOpenModal] = useState(false);
    const [exerciseModalOpen, setExerciseModalOpen] = useState(false);

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index);
    };
    const loadExercises = () => {
        getExercisesByCoachId(supabase, user.id).then(({ data }) => {
            setExercises(data || []);
        });
    };
    const sortedExercises = [...exercises]
        .sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            if (sortOrder === 'asc') {
                return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
            } else {
                return titleA > titleB ? -1 : titleA < titleB ? 1 : 0;
            }
        });
    const handleFilterToggle = () => {
        setShowCustom((prev) => !prev); // Toggle the filter state
    };

    const handleSortToggle = () => {
        setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc')); // Toggle sort order
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };
    const handleClickExercise = (videoId) => {
        setSelectedExercise(videoId);
        setOpenModal(true);
    };

    useEffect(() => {
        if (!user) {
            return;
        }
        loadExercises();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            <Paper sx={{ backgroundColor: "#FBFBFB", borderRadius: "24px", height: "600px", border: "none", display: exerciseModalOpen ? "none" : "block", boxShadow: "unset" }}>
                <Box p={3} pt={4} sx={{ height: "144px" }}>
                    <Box pb={2} display={"flex"} alignItems={"flex-end"} >
                        <Typography sx={{ fontSize: { xl: "16px", lg: "16px", md: "13px", sm: "13px", xs: "13px" }, fontWeight: "600 !important", color: "#525A65" }}>Exercise Library</Typography>
                        <Button
                            sx={button2Style}
                            href="/import-exercises"
                        >
                            + Import exercise
                        </Button>
                        <Button
                            sx={button1Style}
                            onClick={() => setExerciseModalOpen(true)}
                        >
                            + Add new exercise
                        </Button>
                    </Box>

                    <TextField
                        id="outlined-search"
                        label="Search exercise by name..."
                        type="search"
                        fullWidth
                        value={eSearch}
                        sx={searchStyle}
                        onChange={(e) => setESearch(e.target.value)}
                    />
                    <Box pt={"10px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box display={"flex"}>
                            <IconButton onClick={handleSortToggle}>
                                <SortIcon />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" },
                                    color: "#525A65",
                                    display: "flex",
                                    alignItems: "center" // Align items vertically centered
                                }}
                            >
                                Sort
                            </Typography>
                        </Box>
                        <Box display={"flex"}>
                            <IconButton onClick={handleFilterToggle}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill={showCustom ? "#E9FFCE" : "none"} xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" stroke="#525A65" />
                                    {showCustom && <circle cx="7" cy="7" r="3" fill="#02BD36" />}
                                </svg>
                            </IconButton>
                            <Typography sx={{
                                fontSize: { xl: "12px", lg: "10px", md: "9px", sm: "9px", xs: "9px" },
                                color: "#525A65",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                Show only my exercises
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ marginTop: "22px" }} />
                <List sx={{ pl: 2, pr: 2, pt: 1, maxHeight: "432px", overflow: "auto" }}>
                    {sortedExercises
                        .filter((e) =>
                            e.title.toLowerCase().includes(eSearch.toLowerCase()) &&
                            (!showCustom || e.workout === "Custom")
                        )
                        .map((e, i) => (
                            <ListItem key={i} sx={{
                                padding: '12px',
                                backgroundColor: e.workout === "Custom" ? "#E9FFCE" : '#F1F3F4',
                                borderRadius: "8px",
                                marginBottom: '8px',
                            }}>
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {e.videos.length > 0 ?
                                                <>
                                                    <FirstVideoImage videoId={e.videos[0].id} height="56px" width="100px" borderRadius="10px" handleClickExercise={() => handleClickExercise(e.id)} />
                                                    <Box display={"flex"} flexDirection={"column"}>
                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                            {e.title}
                                                        </Typography>
                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                            {e.description?.substring(0, 150)}
                                                        </Typography>
                                                    </Box>
                                                </>
                                                : e.images.length > 0 ?
                                                    <>
                                                        <div style={{ width: '100%', maxWidth: '100px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <a style={{ cursor: "pointer" }} onClick={() => handleClickExercise(e.id)}>
                                                                <img
                                                                    src={e.images[0]}
                                                                    alt={e.title}
                                                                    style={{
                                                                        borderRadius: "10px",
                                                                        maxWidth: 100,
                                                                        maxHeight: 56,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                />
                                                            </a>
                                                        </div>
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                {e.title}
                                                            </Typography>
                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                {e.description?.substring(0, 150)}
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                    : <></>}
                                            {e.images.length === 0 && e.videos.length === 0 ?
                                                <>
                                                    <img
                                                        src={require("../../assets/Rectangle-ccc.png")}
                                                        alt={"empty"}
                                                        style={{
                                                            maxWidth: 100,
                                                            maxHeight: 56,
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    />
                                                    <Box display={"flex"} flexDirection={"column"}>
                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                            {e.title}
                                                        </Typography>
                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                            {e.description?.substring(0, 150)}
                                                        </Typography>
                                                    </Box>
                                                </>
                                                : <></>}
                                        </Box>
                                    }
                                />
                                <IconButton
                                    id="basic-button"
                                    aria-controls={openMenuIndex === i ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    sx={{ padding: "16px 10px 16px 10px !important", }}
                                    aria-expanded={openMenuIndex === i ? 'true' : undefined}
                                    onClick={(event) => handleClick(event, i)} // Pass the index
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenuIndex === i} // Open only if the index matches
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        setSelectedExercise(e);
                                        setExerciseModalOpen(true);
                                        handleClose();
                                    }}>View/Edit</MenuItem>
                                    <MenuItem >Duplicate</MenuItem>
                                    <MenuItem onClick={async () => {
                                        if (window.confirm(`Deleting exercise: "${e.title}". Please confirm.`)) {
                                            await deleteExercise(supabase, e.id);
                                            loadExercises();
                                        }
                                        handleClose();
                                    }}>Delete</MenuItem>
                                </Menu>
                            </ListItem>
                        ))}
                </List>
            </Paper>
            {exerciseModalOpen && (
                <ExerciseModal
                    open={exerciseModalOpen}
                    exercise={selectedExercise}
                    handleClose={() => {
                        setExerciseModalOpen(false);
                        setSelectedExercise(null);
                    }}
                    onSuccess={() => loadExercises()}
                />
            )}
            {openModal && (<TPModal open={openModal} onClose={() => setOpenModal(false)} selected={selectedExercise} exerciseplan={sortedExercises} />)}

        </>

    )
};