import { useEffect, useState } from "react";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Box,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { ExerciseList } from "./ExerciseList";
import { PlanList } from "./PlanList";

export const TrainingPlanRoute = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  return (
    <>
      <PageContainer>
        <Box p={4}>
          <Typography sx={{ fontSize: "24px", fontWeight: "600 !important", color: "#525A65" }}>Plans & Exercises</Typography>
        </Box>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={6} md={6}>
            <ExerciseList user={user} />
          </Grid2>
          <Grid2 xs={12} sm={6} md={6}>
            <PlanList user={user} />
          </Grid2>
        </Grid2>
      </PageContainer >
    </>
  );
};



