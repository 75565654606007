
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Typography,
    Container,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    List, ListItem, ListItemText, ListItemIcon,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { close } from "./style"
import Grid from '@mui/material/Grid';


export const ExerciseModal = ({
    open,
    handleClose,
    exercise,
}) => {
    return (
        <Dialog open={open} onClose={handleClose} fullWidth keepMounted={false} sx={{

            ".MuiPaper-root": {
                borderRadius: "24px",
                width: "529px",
                minHeight: "525px",
                backgroundColor: "#FBFBFB",
            },
        }}>
            <Box sx={{ padding: "40px 23px 16px 23px", minHeight: "525px", maxHeight: "670px" }}>
                <DialogTitle sx={{ color: "#000000", fontWeight: "600 !important", fontSize: "16px", display: "flex" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            Exercise Detail
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
                            <Button onClick={handleClose} startIcon={<CloseIcon />} size="small" sx={close}></Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2, fontSize: "16px", color: "#525A65", fontWeight: "600 !important", mt: 2 }}>
                        {exercise.exercises.title}
                    </DialogContentText>
                    <Typography sx={{ mb: 1, fontSize: "12px", color: "#999999", fontWeight: "400 !important", mt: 2 }}>Description</Typography>
                    <Typography sx={{ mb: 2, fontSize: "14px", color: "#525A65", fontWeight: "400 !important", }}>{exercise.exercises.description}</Typography>
                    <Typography sx={{ mb: 1, fontSize: "12px", color: "#999999", fontWeight: "400 !important", mt: 2 }}>Coach’s Note</Typography>
                    <Typography sx={{ mb: 2, fontSize: "14px", color: "#525A65", fontWeight: "400 !important", }}>{exercise.notes}</Typography>
                </DialogContent >
            </Box >
        </Dialog>
    );
}