
export const typographyStyle = {
  color: "#F1F3F4",
  fontSize: "10px",
  fontWeight: "600 !important",
  backgroundColor: "#525A65",
  padding: "6px 13px 6px 13px",
  borderRadius: "30px",
  lineHeight: "1",
  marginLeft: "19px",
}

export const buttonStyle = {
  marginLeft:"auto",
  color: "#525A65",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "11px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "#525A65",
    color: "white"
  },
  lineHeight: "1",
  maxHeight: "26px",
  textTransform: "none"
};