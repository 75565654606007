export const box1style = {
    p: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
    },
    py: {
        xs: 3,
        sm: 3,
        md: 4,
        lg: 4,
    },
    pb: {
        xs: 6,
    },
    "@media only screen and (max-width: 426px)": {
        padding: "0px !important"
    },
    "@media only screen and (max-width: 900px)": {
        paddingTop: "100px !important"
    },
}

export const box2style = {
    p: 4, width: "100%", borderRadius: "24px", backgroundColor: "#FBFBFB", minHeight: "771px",
    "@media only screen and (max-width: 426px)": {
        fontSize: "16px !important",
        p: 2
    },
    paddingTop: "5px !important",
}

export const typog1style = {
    fontWeight: "600 !important", fontSize: "28px", color: "#525A65", lineHeight: "0.8 !important",
    "@media only screen and (max-width: 426px)": {
        fontSize: "18px !important"
    },
}

export const typog2style = {
    fontWeight: "400 !important", fontSize: "12px", color: "#525A65", mt: 1, "@media only screen and (max-width: 426px)": {
        fontSize: "10px !important"
    },
}

export const typog3style = {
    fontWeight: "600 !important", fontSize: "20px", color: "#525A65"
    , "@media only screen and (max-width: 426px)": {
        fontSize: "16px !important"
    },
}

export const typog4style = {
    fontWeight: "600 !important", fontSize: "14px", color: "#525A65",
    "@media only screen and (max-width: 426px)": {
        fontSize: "10px !important",
    }
}

export const typog5style = {
    fontWeight: "400 !important", fontSize: "10px", color: "#525A65",
    lineHeight: "1 !important",
    "@media only screen and (max-width: 426px)": {
        fontSize: "9px !important",
    }
}


export const grid1style = {
    padding: "12px 0px 0px 0px !important",
    width: {
        xs: "48%",
        sm: "65%",
        md: "67%",
        lg: "76.6%",
        xl: "83.6%",
    }
    // width: { xs: `200px`, sm: `400px`, sm: `800px`, lg: "1300px" },
    // width:{ xs:{4} ,sm:{6} ,md:{6}, lg:{"1300px"}}

}

export const Dropdown = (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.782 -5.32403e-08L6.5 5.475L1.218 -5.15008e-07L-5.51856e-08 1.2625L5.282 6.7375L6.5 8L13 1.2625L11.782 -5.32403e-08Z" fill="#525A65" />
    </svg>
)


export const Dropup = (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.218 8L6.5 2.525L11.782 8L13 6.7375L7.718 1.2625L6.5 -8.76163e-07L1.50552e-08 6.7375L1.218 8Z" fill="#525A65" />
    </svg>
)



export const srcDoc = (vid) => (`
    <style>
      * {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
      body, html {
        height: 100%;
      }
      img, svg {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      svg {
        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
        transition: all 250ms ease-in-out;
      }
      body:hover svg {
        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
        transform: scale(1.2);
      }
    </style>
    <a style="cursor:pointer" id="iframe">
      <img src='https://img.youtube.com/vi/${vid}/hqdefault.jpg' alt='Exercise video'>
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M16 0C7.172 0 0 7.172 0 16C0 24.828 7.172 32 16 32C24.828 32 32 24.828 32 16C32 7.172 24.828 0 16 0ZM16 1.6C23.964 1.6 30.4 8.036 30.4 16C30.4 23.964 23.964 30.4 16 30.4C8.036 30.4 1.6 23.964 1.6 16C1.6 8.036 8.036 1.6 16 1.6ZM11.6 8.8V23.2L23.6 16L11.6 8.8Z' fill='white' />
      </svg>
    </a>
    <script>
    document.getElementById("iframe").onclick = 
          function() {
              alert("iframe clicked");
              TPModal();
          }
    </script>
  `);