import { useEffect, useState } from "react"
import {
    Box,
    Button,
    IconButton,
    Typography,
    Container,
    List, ListItem, ListItemText, ListItemIcon,
} from "@mui/material";
import { supabase } from "../../services/supabase";
import { getPlanByIdClient } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { FirstVideoImage } from "../TPDisplay/TPDisplay";
import { TPModal } from "../TPDisplay/OpenmodelTP";
import { TrainingPlanModal } from "../TrainingPlanModal/TrainingPlanModal"
import { LeftArrow, InfoIcon } from "./style"
import { ExerciseModal } from "./ExerciseModal";


export const ViewExerciseAndPlan = ({ athelete, back, title, reload }) => {
    const [openModal, setOpenModal] = useState(false);
    const [ExerciseModals, setExerciseModals] = useState(false);
    const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
    const [ModalData, setModalData] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [Plans, setPlans] = useState(athelete[0].plan[0]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const handleClickExercise = (videoId) => {
        setSelectedExercise(videoId);
        setOpenModal(true);
    };

    console.log(Plans)
    const loadPlans = () => {
        getPlanByIdClient(supabase, Plans.id).then(({ data }) => {
            setPlans(data[0] || []);
            reload();
        });
    };
    useEffect(() => {
        if (trainingPlanOpen) {
            title("Edit Training Plan")
        } else {
            title("Training Plan")
        }
    }, [trainingPlanOpen])

    const groupExercisesBySuperset = (exercises) => {
        const groups = [];
        let currentGroup = [];
        let currentSupersetId = null;

        const pushGroup = () => {
            if (currentGroup.length > 0) {
                groups.push({
                    type: currentSupersetId ? 'superset' : 'normal',
                    exercises: currentGroup.map(exercise => ({
                        exercises: exercise.exercises,
                        sets: currentSupersetId ? currentGroup[0].sets : exercise.sets,
                        notes: exercise.notes,
                        isSuperset: exercise.isSuperset,
                        supersetGroup: exercise.supersetGroup,
                        order: exercise.order
                    }))
                });
                currentGroup = [];
            }
        };

        exercises.forEach((exercise) => {
            if (exercise.isSuperset) {
                if (exercise.supersetGroup !== currentSupersetId) {
                    pushGroup();
                    currentSupersetId = exercise.supersetGroup;
                }
                currentGroup.push({ ...exercise });
            } else {
                pushGroup();
                currentSupersetId = null;
                groups.push({
                    type: 'normal',
                    exercises: [{
                        exercises: exercise.exercises,
                        sets: exercise.sets,
                        notes: exercise.notes,
                        isSuperset: exercise.isSuperset,
                        supersetGroup: exercise.supersetGroup,
                        order: exercise.order
                    }]
                });
            }
        });

        pushGroup();
        return groups;
    };

    return (
        <>
            <Container maxWidth="600px" maxHeight={"555px"} sx={{
                height: "555px",
                backgroundColor: '#F1F3F4', padding: 2,
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                display: trainingPlanOpen ? "none" : "block"
            }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Button startIcon={<LeftArrow />} onClick={back} sx={{
                        fontSize: "12px", textTransform: "none", ".MuiButton-icon": {
                            marginRight: "3px"
                        }
                    }}>
                        <span style={{ marginTop: "1px" }}>
                            Back
                        </span>
                    </Button>
                    <Button variant="outlined" sx={{
                        borderRadius: "30px", color: "#2998E9", fontSize: "12px", borderColor: "#2998E9", maxHeight: "26px", lineHeight: "1", ":hover": {
                            backgroundColor: "unset",
                            color: "#2998E9",
                            borderColor: "#2998E9"
                        },
                        padding: "6px 9px",
                        textTransform: "none"
                    }}
                        onClick={() => { setTrainingPlanOpen(true); setSelectedPlan(Plans); }}>
                        Edit training plan
                    </Button>
                </Box>
                <Typography fontWeight="600 !important" fontSize={"16px"} color={"#525A65"} mb={2} ml={1}>
                    {Plans.name}
                </Typography>
                <Typography mb={2} ml={1} fontWeight="400 !important" fontSize={"10px"} color={"#525A65"}>
                    {Plans.overview}
                </Typography>
                <Typography fontWeight="600 !important" fontSize={"16px"} color={"#525A65"} mb={1} ml={1}>
                    List of Exercises
                </Typography>
                <List sx={{ maxHeight: "355px", height: "355px", overflow: "auto" }}>
                    {groupExercisesBySuperset(Plans.exercise_plans.sort((a, b) => a.order - b.order))
                        .map((group, groupIndex) => (
                            <Box key={groupIndex} sx={{
                                marginBottom: 1,
                                marginRight: 1,
                                position: 'relative',
                                ...(group.type === 'superset' && {
                                    position: 'relative',
                                })
                            }}>
                                {group.type === 'superset' ? (
                                    <Box sx={{
                                        borderRadius: '24px',
                                        padding: '8px',
                                        marginBottom: '8px',
                                        backgroundColor: '#FBFBFB',
                                        position: 'relative'
                                    }}>
                                        <Box sx={{
                                            position: 'absolute',
                                            left: '8px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            zIndex: 1
                                        }}>
                                            <Box sx={{
                                                border: "1px solid #DAD9D8",
                                                borderRadius: '50%',
                                                width: 30,
                                                height: 30,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#FBFBFB'
                                            }}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    fontWeight: '600',
                                                    color: "#525A65",
                                                    lineHeight: 1
                                                }}>
                                                    {"x" + (group.exercises[0].sets || 1)}
                                                </span>
                                            </Box>
                                        </Box>
                                        {group.exercises.map(({ exercises: e, sets, notes }, i) => (
                                            <ListItem key={i} sx={{
                                                padding: '12px',
                                                ...(i < group.exercises.length - 1 && {
                                                    borderBottom: '1px solid #E5E8EB',
                                                    opacity: 1
                                                }),
                                                backgroundColor: '#FBFBFB',
                                                borderRadius: "24px",
                                                paddingLeft: '50px'
                                            }}>
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {e.videos.length > 0 ?
                                                                <>
                                                                    <FirstVideoImage videoId={e.videos[0].id} height="56px" width="100px" borderRadius="10px" handleClickExercise={() => handleClickExercise(e.id)} />
                                                                    <Box display={"flex"} flexDirection={"column"}>
                                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                            {e.title}
                                                                        </Typography>
                                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                            {notes}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : e.images.length > 0 ?
                                                                    <>
                                                                        <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <a style={{ cursor: "pointer" }} onClick={() => handleClickExercise(e.id)}>
                                                                                <img
                                                                                    src={e.images[0]}
                                                                                    alt={e.title}
                                                                                    style={{
                                                                                        borderRadius: "10px",
                                                                                        maxWidth: 100,
                                                                                        maxHeight: 56,
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <Box display={"flex"} flexDirection={"column"}>
                                                                            <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                                {e.title}
                                                                            </Typography>
                                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                                {notes}
                                                                            </Typography>
                                                                            <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#838F99"} ml={1} mt={0.5}>
                                                                                {e.type}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                    : <></>}
                                                            {e.images.length === 0 && e.videos.length === 0 ?
                                                                <>
                                                                    <img
                                                                        src={require("../../assets/Rectangle-ccc.png")}
                                                                        alt={"empty"}
                                                                        style={{
                                                                            maxWidth: 100,
                                                                            maxHeight: 56,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                    />
                                                                    <Box display={"flex"} flexDirection={"column"}>
                                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                            {e.title}
                                                                        </Typography>
                                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                            {notes}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : <></>}
                                                        </Box>
                                                    }
                                                />
                                                <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                                                    onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                    </Box>
                                ) : (
                                    group.exercises.map(({ exercises: e, sets, notes }, i) => (
                                        <ListItem key={i} sx={{
                                            padding: '12px',
                                            backgroundColor: '#FBFBFB',
                                            borderRadius: "24px",
                                            marginBottom: '8px'
                                        }}>
                                            <ListItemIcon>
                                                <Box sx={{
                                                    border: "1px solid #DAD9D8",
                                                    borderRadius: '50%',
                                                    width: 30,
                                                    height: 30,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '12px'
                                                }}>
                                                    <span style={{
                                                        fontSize: "12px",
                                                        fontWeight: '600',
                                                        color: "#525A65",
                                                        lineHeight: 1
                                                    }}>
                                                        {"x" + (sets || 1)}
                                                    </span>
                                                </Box>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {e.videos.length > 0 ?
                                                            <>
                                                                <FirstVideoImage videoId={e.videos.length > 0 ? e.videos[0].id : null} height="56px" width="100px" borderRadius="10px" handleClickExercise={() => handleClickExercise(e.id)} />
                                                                <Box display={"flex"} flexDirection={"column"}>
                                                                    <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                        {e.title}
                                                                    </Typography>
                                                                    <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                        {notes}
                                                                    </Typography>
                                                                </Box>
                                                            </>
                                                            : e.images.length > 0 ?
                                                                <>
                                                                    <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <a style={{ cursor: "pointer" }} onClick={() => handleClickExercise(e.id)}>
                                                                            <img
                                                                                src={e.images[0]}
                                                                                alt={e.title}
                                                                                style={{
                                                                                    borderRadius: "10px",
                                                                                    maxWidth: 100,
                                                                                    maxHeight: 56,
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <Box display={"flex"} flexDirection={"column"}>
                                                                        <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                            {e.title}
                                                                        </Typography>
                                                                        <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                            {notes}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                                : <></>}
                                                        {e.images.length === 0 && e.videos.length === 0 ?
                                                            <>
                                                                <img
                                                                    src={require("../../assets/Rectangle-ccc.png")}
                                                                    alt={"empty"}
                                                                    style={{
                                                                        maxWidth: 100,
                                                                        maxHeight: 56,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                />
                                                                <Box display={"flex"} flexDirection={"column"}>
                                                                    <Typography component="span" fontWeight="600  !important" fontSize={"12px"} color={"#525A65"} ml={1} mb={1}>
                                                                        {e.title}
                                                                    </Typography>
                                                                    <Typography component="span" fontWeight="400  !important" fontSize={"10px"} color={"#525A65"} ml={1}>
                                                                        {notes}
                                                                    </Typography>
                                                                </Box>
                                                            </>
                                                            : <></>}
                                                    </Box>
                                                }
                                            />
                                            <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                                                onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                                                <InfoIcon />
                                            </IconButton>
                                        </ListItem>
                                    ))
                                )}
                            </Box>
                        ))}
                </List>
                {ExerciseModals && <ExerciseModal open={ExerciseModals} handleClose={() => setExerciseModals(false)} exercise={ModalData} />}

                {openModal && (<TPModal open={openModal} onClose={() => setOpenModal(false)} selected={selectedExercise} exerciseplan={Plans.exercise_plans.sort((a, b) => a - b).map(({ exercises: e }) => (e))} />)}
            </Container >
            {trainingPlanOpen && (
                <TrainingPlanModal
                    exercises={exercises}
                    open={trainingPlanOpen}
                    plan={selectedPlan}
                    isvisible={true}
                    handleClose={() => {
                        setTrainingPlanOpen(false);
                        setSelectedPlan(null);

                    }}
                    onSuccess={() => { loadPlans(); }}
                />
            )}
        </>
    )
}