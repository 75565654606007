import { useEffect, useState } from "react"
import {
    Box,
    IconButton,
    ListItem,
    Paper,
    Typography,
    List, ListItemText
} from "@mui/material";
import {
    ChevronLeft,
    ChevronRight,
} from "@mui/icons-material";
import { supabase } from "../../services/supabase";
import { getPlanByIdClient } from "../../services/query";


export const CardDayAtheletesPlan = ({ athlete, OnchangeEvents, onChangeDateTable, curDate }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(null); // State to track the selected day
    const [Plans, setPlans] = useState([]); // State to track the selected day

    const updateDateDisplay = () => {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week
        const monthName = startOfWeek.toLocaleString('default', { month: 'long' }).toUpperCase();
        const startDay = startOfWeek.getDate();
        const endDay = endOfWeek.getDate();
        return `${monthName} ${startDay}-${endDay}`;
    };

    const handlePrevClick = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 7);
        setCurrentDate(newDate);
    };

    const handleNextClick = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 7);
        setCurrentDate(newDate);
    };

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    const fetchPlans = async () => {
        const plans = await Promise.all(
            athlete.events.map(event => getPlanByIdClient(supabase, event.plan_client_id).then(plan => ({
                plan: plan.data,
                calendarId: athlete.calendars[0].payload.calendarId,
                resource: event.payload,
                eventId: event.id,
                events: event,
                date: new Date(event.date).getDate(),
                month: new Date(event.date).getMonth(),
                EventDate: event.date
            })))
        );
        setPlans(plans);
    };


    const daysOfWeek = Array.from({ length: 7 }, (_, index) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + index);
        const dayEvents = Plans.filter(event => event.date === date.getDate() && event.month === date.getMonth());
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleString('en-US', options);
        return {
            date: date.getDate(),
            tableDate: formattedDate,
            events: dayEvents,
            selected: selectedDay === formattedDate // Check if this day is selected
        };
    });

    useEffect(() => {
        fetchPlans();
        const today = curDate ? new Date(curDate) : new Date();
        const formattedToday = today.toLocaleString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
        onChangeDateTable(formattedToday);
        setSelectedDay(formattedToday);
    }, [athlete]);

    useEffect(() => {
        const selectedEvents = daysOfWeek.find(day => day.tableDate === selectedDay)?.events || [];
        OnchangeEvents(selectedEvents);
    }, [Plans, selectedDay, OnchangeEvents]);

    const handleDayClick = (dayDate, TableDate) => {
        setSelectedDay(TableDate);
        onChangeDateTable(TableDate);
        const selectedevents = daysOfWeek.find(day => day.date === dayDate)?.events || [];
        OnchangeEvents(selectedevents);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', mt: 2, mb: 5, flexDirection: 'column', alignItems: 'center' }}>
            <Typography fontSize={"12px"} component="div" sx={{ mb: 2 }}>
                <span style={{ marginBottom: "20px", color: "#525A65" }}>{updateDateDisplay().toUpperCase()}</span>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: "1440px" }}>
                <IconButton onClick={handlePrevClick}>
                    <ChevronLeft />
                </IconButton>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, mx: 1 }}>
                    {daysOfWeek.map((day, index) => (
                        <Paper
                            key={index}
                            onClick={() => handleDayClick(day.date, day.tableDate)} // Handle day click
                            sx={{
                                width: 137,
                                height: 107,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                mx: 0.5,
                                borderRadius: "8px",
                                cursor: 'pointer',
                                backgroundColor: selectedDay === null ? '#fff' : (day.selected ? '#F0F9FF' : '#fff'),
                                color: selectedDay === null ? '#525A65' : (day.selected ? '#043A8D' : '#525A65'),
                                border: selectedDay === null ? 'none' : (day.selected ? '2px solid #2998E9' : 'none'),
                                boxShadow: "unset"
                            }}
                        >
                            <Box pl={1.5} pt={1}>
                                <Typography fontSize={"18px"} fontWeight={"700 !important"} component="div">
                                    {day.date}
                                </Typography>
                                <List sx={{ maxHeight: "64px", height: "64px", overflow: "auto", p: 0 }}>
                                    {day.events.map((event, idx) => (
                                        <ListItem key={idx} sx={{ padding: "0 !important", width: "fit-content", }}>
                                            <ListItemText sx={{ margin: "0px !important" }} primary={
                                                <Typography
                                                    key={idx}
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        backgroundColor: '#A080E8',
                                                        color: '#fff',
                                                        borderRadius: "30px",
                                                        px: 1,
                                                        fontSize: "9px",
                                                        mt: 0.5,
                                                        mr: 1
                                                    }}
                                                >
                                                    {event.plan[0]?.name.length > 19 ? event.plan[0].name.substring(0, 15) + "..." : event.plan[0]?.name}
                                                </Typography>
                                            } />
                                        </ListItem>

                                    ))}
                                </List>
                            </Box>
                        </Paper>
                    ))}
                </Box>
                <IconButton onClick={handleNextClick}>
                    <ChevronRight />
                </IconButton>
            </Box>
        </Box>
    );
};
