import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSupabase } from "../../providers/AuthContextProvider";
import { getPlanByIdClient, getPlanByIdPublic } from "../../services/query";
import { PageContainer } from "../PageContainer/PageContainer";
import { Box, Divider, IconButton, Typography, useMediaQuery, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { decodeTPURLId } from "../../utils/bas64";
import { ExerciseUnits } from "../TrainingPlanModal/TrainingPlanModal";
import Grid from '@mui/material/Grid';
import { useAuth } from "../../providers/AuthContextProvider";
import * as All from './style'
import { TPModal } from './OpenmodelTP'
import { InfoIcon } from "../ClientDetailTraining/style";
import { ExerciseModal } from "../ClientDetailTraining/ExerciseModal";


const setsCountStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',

  fontSize: '14px',
  fontWeight: 400,
  position: 'absolute',
  left: '16px',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: "8px",
  bgcolor: "transparent",
  color: "#525A65",
  border: "solid 1px #B0B0B0",
  zIndex: 1000,
};

const groupExercisesBySuperset = (exercises) => {
  const groups = [];
  let currentGroup = [];
  let currentSupersetId = null;

  const pushGroup = () => {
    if (currentGroup.length > 0) {
      groups.push({
        type: currentSupersetId ? 'superset' : 'normal',
        exercises: currentGroup.map(exercise => ({
          exercises: exercise.exercises,
          sets: currentSupersetId ? currentGroup[0].sets : exercise.sets,
          notes: exercise.notes,
          isSuperset: exercise.isSuperset,
          supersetGroup: exercise.supersetGroup,
          order: exercise.order
        }))
      });
      currentGroup = [];
    }
  };

  exercises.forEach((exercise) => {

    if (exercise.isSuperset) {
      if (exercise.supersetGroup !== currentSupersetId) {
        pushGroup();
        currentSupersetId = exercise.supersetGroup;
      }
      currentGroup.push({ ...exercise });
    } else {
      pushGroup();
      currentSupersetId = null;
      groups.push({
        type: 'normal',
        exercises: [{
          exercises: exercise.exercises,
          sets: exercise.sets,
          notes: exercise.notes,
          isSuperset: exercise.isSuperset,
          supersetGroup: exercise.supersetGroup,
          order: exercise.order
        }]
      });
    }
  });

  pushGroup();
  return groups;
};

export const TPDisplay = () => {
  const [plan, setPlan] = useState(null);
  const { bid } = useParams();
  const supabase = useSupabase();
  const { session } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [exerciseModals, setExerciseModals] = useState(false);
  const [modalData, setModalData] = useState(null);

  const fetchPlan = async () => {
    const { email, id } = decodeTPURLId(bid);
    const { data: plan } = await getPlanByIdClient(supabase, id, email);
    setPlan(plan[0]);
  };
  const handleClickExercise = (videoId) => {
    setSelectedExercise(videoId); // Set the selected video ID
    setOpenModal(true); // Open the modal
  };

  useState(() => {
    fetchPlan();
  }, [bid]);

  if (!plan) {
    return null;
  }

  return (
    <Box
      sx={{ ...All.box1style, background: "##f4f5f7" }}>
      <Box sx={{ p: 4 }}>
        {!session ?
          <Box sx={{ display: "flex", pb: 2 }}>
            <Typography sx={{ fontWeight: "700 !important", fontSize: "12px", color: "#999999", pr: 1 }}>
              Made with
            </Typography>
            <img
              key={require("../../assets/clientlogo.png")}
              src={require("../../assets/clientlogo.png")}
              alt={"empty"}
              style={{
                width: "83px",
                height: "11px",
                marginTop: "3px",
              }}
            />
          </Box> : null}
        <Typography variant="subtitle" sx={All.typog1style}>{plan.name}</Typography>
        <br />
        <Typography variant="subtitle" sx={All.typog2style}>{plan.overview}</Typography>
      </Box>
      <Box sx={{ padding: "16px 16px 21px 32px" }}>
        <Typography variant="subtitle" sx={All.typog3style}>List of Exercises</Typography>
      </Box>

      <List>
        {groupExercisesBySuperset(plan.exercise_plans.sort((a, b) => a.order - b.order))
          .map((group, groupIndex) => (
            <Box key={groupIndex} sx={{
              marginBottom: 1,
              marginRight: 1,
              position: 'relative',
              ...(group.type === 'superset' && {
                position: 'relative',
              })
            }}>
              {group.type === 'superset' ? (
                <Box sx={{
                  borderRadius: '24px',
                  padding: '8px',
                  marginBottom: '8px',
                  backgroundColor: '#FBFBFB',
                  position: 'relative'
                }}>
                  <Box sx={{
                    position: 'absolute',
                    left: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1
                  }}>
                    <Box sx={{
                      border: "1px solid #DAD9D8",
                      borderRadius: '50%',
                      width: 30,
                      height: 30,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#FBFBFB'
                    }}>
                      <span style={{
                        fontSize: "12px",
                        fontWeight: '600',
                        color: "#525A65",
                        lineHeight: 1
                      }}>
                        {"x" + (group.exercises[0].sets || 1)}
                      </span>
                    </Box>
                  </Box>
                  {group.exercises.map(({ exercises: e, sets, notes }, i) => (
                    <ListItem key={i} sx={{
                      padding: '12px',
                      ...(i < group.exercises.length - 1 && {
                        borderBottom: '1px solid #E5E8EB',
                        opacity: 1
                      }),
                      backgroundColor: '#FBFBFB',
                      borderRadius: "24px",
                      paddingLeft: '50px'
                    }}>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {e.videos.length > 0 ?
                              <>
                                <FirstVideoImage videoId={e.videos[0].id} height="84px" width="150px" borderRadius="10px" handleClickExercise={() => handleClickExercise(e.id)} />
                                <Box display={"flex"} flexDirection={"column"}>
                                  <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                    {e.title}
                                  </Typography>
                                  <Typography component="span" fontWeight="400  !important" fontSize={"14px"} color={"#525A65"} ml={1}>
                                    {notes}
                                  </Typography>
                                </Box>
                              </>
                              : e.images.length > 0 ?
                                <>
                                  <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <a style={{ cursor: "pointer" }} onClick={() => handleClickExercise(e.id)}>
                                      <img
                                        src={e.images[0]}
                                        alt={e.title}
                                        style={{
                                          borderRadius: "10px",
                                          maxWidth: 150,
                                          maxHeight: 84,
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <Box display={"flex"} flexDirection={"column"}>
                                    <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                      {e.title}
                                    </Typography>
                                    <Typography component="span" fontWeight="400  !important" fontSize={"14px"} color={"#525A65"} ml={1}>
                                      {notes}
                                    </Typography>
                                  </Box>
                                </>
                                : <></>}
                            {e.images.length === 0 && e.videos.length === 0 ?
                              <>
                                <img
                                  src={require("../../assets/Rectangle-ccc.png")}
                                  alt={"empty"}
                                  style={{
                                    maxWidth: 150,
                                    maxHeight: 84,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <Box display={"flex"} flexDirection={"column"}>
                                  <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                    {e.title}
                                  </Typography>
                                  <Typography component="span" fontWeight="400  !important" fontSize={"14px"} color={"#525A65"} ml={1}>
                                    {notes}
                                  </Typography>
                                </Box>
                              </>
                              : <></>}
                          </Box>
                        }
                      />
                      <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                        onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                        <InfoIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </Box>
              ) : (
                group.exercises.map(({ exercises: e, sets, notes }, i) => (
                  <ListItem key={i} sx={{
                    padding: '12px',
                    backgroundColor: '#FBFBFB',
                    borderRadius: "24px",
                    marginBottom: '8px'
                  }}>
                    <ListItemIcon>
                      <Box sx={{
                        border: "1px solid #DAD9D8",
                        borderRadius: '50%',
                        width: 30,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '12px'
                      }}>
                        <span style={{
                          fontSize: "12px",
                          fontWeight: '600',
                          color: "#525A65",
                          lineHeight: 1
                        }}>
                          {"x" + (sets || 1)}
                        </span>
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {e.videos.length > 0 ?
                            <>
                              <FirstVideoImage videoId={e.videos.length > 0 ? e.videos[0].id : null} height="84px" width="150px" borderRadius="10px" handleClickExercise={() => handleClickExercise(e.id)} />
                              <Box display={"flex"} flexDirection={"column"}>
                                <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                  {e.title}
                                </Typography>
                                <Typography component="span" fontWeight="400  !important" fontSize={"14px"} color={"#525A65"} ml={1}>
                                  {notes}
                                </Typography>
                              </Box>
                            </>
                            : e.images.length > 0 ?
                              <>
                                <div style={{ width: '100%', maxWidth: '150px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <a style={{ cursor: "pointer" }} onClick={() => handleClickExercise(e.id)}>
                                    <img
                                      src={e.images[0]}
                                      alt={e.title}
                                      style={{
                                        borderRadius: "10px",
                                        maxWidth: 150,
                                        maxHeight: 84,
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </a>
                                </div>
                                <Box display={"flex"} flexDirection={"column"}>
                                  <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                    {e.title}
                                  </Typography>
                                  <Typography component="span" fontWeight="400  !important" fontSize={"14px"} color={"#525A65"} ml={1}>
                                    {notes}
                                  </Typography>
                                </Box>
                              </>
                              : <></>}
                          {e.images.length === 0 && e.videos.length === 0 ?
                            <>
                              <img
                                src={require("../../assets/Rectangle-ccc.png")}
                                alt={"empty"}
                                style={{
                                  maxWidth: 150,
                                  maxHeight: 84,
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                              <Box display={"flex"} flexDirection={"column"}>
                                <Typography component="span" fontWeight="600  !important" fontSize={"16px"} color={"#525A65"} ml={1} mb={1}>
                                  {e.title}
                                </Typography>
                                <Typography component="span" fontWeight="400  !important" fontSize={"15px"} color={"#525A65"} ml={1}>
                                  {notes}
                                </Typography>
                              </Box>
                            </>
                            : <></>}
                        </Box>
                      }
                    />
                    <IconButton edge="end" aria-label="info" sx={{ marginRight: "0px !important" }}
                      onClick={() => { setExerciseModals(true); setModalData(group.exercises[i]); }}>
                      <InfoIcon />
                    </IconButton>
                  </ListItem>
                ))
              )}
            </Box>
          ))}
      </List>
      {openModal && (
        <TPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          selected={selectedExercise}
          exerciseplan={plan.exercise_plans.sort((a, b) => a.order - b.order).map(({ exercises: e }) => (e))}
        />
      )}
      {exerciseModals && modalData && (
        <ExerciseModal
          open={exerciseModals}
          handleClose={() => setExerciseModals(false)}
          exercise={{
            exercises: modalData.exercises,
            sets: modalData.sets,
            notes: modalData.notes
          }}
        />
      )}
    </Box >
  );
};


export const ShowMoreText = ({ text }) => {
  const fullText = typeof text === 'string' ? text : 'Invalid text provided';;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const isSmallScreen = useMediaQuery('(max-width:435px)');

  return (
    <>
      <span className="show-more" onClick={toggleText} style={{
        cursor: 'pointer', textDecoration: 'none', float: "right",
        "@media only screen and (max-width: 1025px)": {
          marginLeft: "5px"
        }
      }}>
        {fullText.length > 250 ? (
          isExpanded ? (
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.218 8L6.5 2.525L11.782 8L13 6.7375L7.718 1.2625L6.5 -8.76163e-07L1.50552e-08 6.7375L1.218 8Z" fill="#525A65" />
            </svg>
          ) : (
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.782 -5.32403e-08L6.5 5.475L1.218 -5.15008e-07L-5.51856e-08 1.2625L5.282 6.7375L6.5 8L13 1.2625L11.782 -5.32403e-08Z" fill="#525A65" />
            </svg>
          )
        ) : null}
      </span>
      <Typography variant="subtitle" sx={All.typog5style}>
        {isExpanded ? isSmallScreen ? fullText?.substring(0, 150) : fullText : `${fullText?.substring(0, isSmallScreen ? 50 : 350)}...`}
      </Typography >

    </>
  );
};


export const FirstVideoImage = ({ videoId, handleClickExercise, height = "84px", width = "150px", borderRadius = "16px" }) => {
  return (
    <div style={{ width: width, height: height, position: 'relative', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <a style={{ cursor: "pointer", position: 'relative', width: '100%', height: '100%' }} onClick={handleClickExercise}>
        <img
          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          alt='Exercise video'
          style={{
            width: width, // Fixed width
            height: height, // Fixed height
            objectFit: "cover", // Ensures the image covers the area without black spaces
            borderRadius: borderRadius,
            transition: "transform 250ms ease-in-out"
          }}
          className="responsive-img"
        />
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            filter: "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))",
            transition: "all 250ms ease-in-out",
            cursor: "pointer"
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.filter = "drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%))";
            e.currentTarget.style.transform = "translate(-50%, -50%) scale(1.2)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.filter = "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))";
            e.currentTarget.style.transform = "translate(-50%, -50%) scale(1)";
          }}
        >
          <path d='M16 0C7.172 0 0 7.172 0 16C0 24.828 7.172 32 16 32C24.828 32 32 24.828 32 16C32 7.172 24.828 0 16 0ZM16 1.6C23.964 1.6 30.4 8.036 30.4 16C30.4 23.964 23.964 30.4 16 30.4C8.036 30.4 1.6 23.964 1.6 16C1.6 8.036 8.036 1.6 16 1.6ZM11.6 8.8V23.2L23.6 16L11.6 8.8Z' fill='white' />
        </svg>
      </a>
    </div>

  )
}